import "./industrymanagement.css"

import SectionOne from "../../../Components/Sectionone/Sectionone";
import logo from "../../../Assets/landingPage/carousel-2.png";
import SectionThree from "../../../Components/SectionThree/SectionThree";
import content from "../../../Model/ErpSection.js";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import {services_heroSection_Content} from "../../../Model/HeroSection.content"
import { Accordion, AccordionTab } from 'primereact/accordion';
import LandingSection from "../../../Components/LandingSection/LandingSection";
import { useEffect } from 'react';
import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";

const IndustryManagement =()=>{
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
    const Indusmanagement = services_heroSection_Content.IndustryManagement
     
    return(
        <div className="industrymanagement-main-container">
        <LandingSection Background="https://images.pexels.com/photos/5473298/pexels-photo-5473298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" Title={Indusmanagement.title} Description={Indusmanagement.description} />
        <ServiceMenu/>
   
      {/* <SectionOne
        title={Schoolmanagement.title}
        description={Schoolmanagement.description}
        image={Schoolmanagement.image}
        subtitle={Schoolmanagement.subtitle}
        subimage={Schoolmanagement.subimage}
      /> */}
      <ContentTemplate description={Indusmanagement.subtitle} image={Indusmanagement.subimage} isReverse={false} />
      <ContentTemplate description={Indusmanagement.subtitleone} image={Indusmanagement.subimageone} isReverse={true} />
      <ContentTemplate description={Indusmanagement.subtitletwo} image={Indusmanagement.subimagetwo} isReverse={false} />
      <div className="Sectionfourmain">

<div className="sectionfour-left">

  <div className="sectionfour-text">
    <div className="SectionFourHead-title">Benifits Of Generic Solution</div>
    <Accordion activeIndex={0}>
      <AccordionTab header="Efficient Workflow Optimization:">
        <p className="m-0">
        Generic Software Solutions' Industry Management Software provides an integrated platform to optimize workflow processes. By automating repetitive tasks, simplifying complex procedures, and centralizing data management, it enables businesses to operate more efficiently. This software promotes seamless collaboration among teams, ensuring timely execution of tasks and reducing the risk of errors. As a result, organizations can allocate resources more effectively and maximize their operational efficiency.
        </p>
      </AccordionTab>
      <AccordionTab header="Real-time Data Insights:">
        <p className="m-0">
        Access to real-time data is crucial for informed decision-making. Generic Software Solutions' Industry Management Software offers powerful analytics and reporting capabilities, allowing businesses to gather insights from various aspects of their operations. Managers can monitor key performance indicators, track project progress, and identify trends to make data-driven decisions. These valuable insights help organizations identify opportunities for improvement, forecast market trends, and respond quickly to changing business demands.
        </p>
      </AccordionTab>
      <AccordionTab header="Enhanced Resource Allocation:">
        <p className="m-0">
        Efficient resource allocation is a critical factor in the success of any organization. With Generic Software Solutions' Industry Management Software, businesses can optimize resource allocation by identifying areas where resources are underutilized or overburdened. The software assists in balancing workloads, managing inventory levels, and allocating human resources effectively. By ensuring that resources are utilized optimally, businesses can reduce costs, increase productivity, and improve overall profitability.
        </p>
      </AccordionTab>
      <AccordionTab header="Improved Compliance and Risk Management:">
        <p className="m-0">
        Generic Software Solutions' Industry Management Software incorporates robust security and compliance features. It helps businesses adhere to industry regulations and best practices, reducing the risk of non-compliance penalties and potential legal issues. The software also enables organizations to implement risk management strategies effectively. By identifying and mitigating potential risks, businesses can safeguard their assets, protect their reputation, and build trust with stakeholders.
        </p>
      </AccordionTab>
      {/* <AccordionTab header="Smart way to conduct assessments +">
            <p className="m-0">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
        </AccordionTab>
        <AccordionTab header="Smart way to conduct assessments +">
            <p className="m-0">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
        </AccordionTab> */}
    </Accordion>
  </div>
</div>
<div className="sectionfour-right">
  <div className="sectionfour-right-image">
    <img src="https://qacraft.com/wp-content/uploads/2022/03/ERP-Software-knowledge-for-testers.png" alt="" />
  </div>
</div>
</div>
      {/* <SectionThree  content={content}/> */}
      </div>
    );
};
export default IndustryManagement;