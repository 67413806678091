import "./SchoolManagement.css"

import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";
import logo from "../../../Assets/landingPage/carousel-2.png";
import SectionThree from "../../../Components/SectionThree/SectionThree";
import content from "../../../Model/ErpSection.js";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import {services_heroSection_Content} from "../../../Model/HeroSection.content"
import LandingSection from "../../../Components/LandingSection/LandingSection";
import { useEffect } from 'react';


const SchoolManagement =()=>{
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
    const Schoolmanagement = services_heroSection_Content.Schoolmanagement
     
    return(
        <div className="schoolmanagement-main-container">
                    <LandingSection Background="https://images.pexels.com/photos/5212700/pexels-photo-5212700.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" Title={Schoolmanagement.title} Description={Schoolmanagement.description} />
<ServiceMenu/>
      {/* <SectionOne
        title={Schoolmanagement.title}
        description={Schoolmanagement.description}
        image={Schoolmanagement.image}
        subtitle={Schoolmanagement.subtitle}
        subimage={Schoolmanagement.subimage}
      /> */}
      <ContentTemplate description={Schoolmanagement.subtitle} image={Schoolmanagement.subimage} isReverse={false} />
      <ContentTemplate description={Schoolmanagement.subtitleone} image={Schoolmanagement.subimageone} isReverse={true} />
      <ContentTemplate description={Schoolmanagement.subtitletwo} image={Schoolmanagement.subimagetwo} isReverse={false} />
      <SectionThree  content={content}/>
      </div>
    );
};
export default SchoolManagement;