import "./Mobileapp.css"

import SectionOne from "../../../Components/Sectionone/Sectionone";
import logo from "../../../Assets/landingPage/carousel-2.png";
import SectionThree from "../../../Components/SectionThree/SectionThree";
import content from "../../../Model/ErpSection.js";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import {services_heroSection_Content} from "../../../Model/HeroSection.content"
import { Accordion, AccordionTab } from 'primereact/accordion';
import AppMockUP from "../../../Assets/image/App-Mockup.png"
import { motion } from 'framer-motion'
import LandingSection from "../../../Components/LandingSection/LandingSection";
import MobileSectionTwo from "./MobileSectionTwo/MobileSectionTow";
import ContainerIntro from "../../../Components/ContainerIntro/ContainerIntro";
import { useEffect } from 'react';
import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";

const MobileappSerives =()=>{
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
    const Mobileapp = services_heroSection_Content.MobileappService
     
    return(
        <div className="Mobileapp-main-container">
    <LandingSection Background="https://images.pexels.com/photos/3850441/pexels-photo-3850441.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" Title={Mobileapp.title} Description={Mobileapp.description} />
    <ServiceMenu/>
   
      {/* <SectionOne
        title={Mobileapp.title}
        description={Mobileapp.description}
        image={Mobileapp.image}
        subtitle={Mobileapp.subtitle}
        subimage={Mobileapp.subimage}
      /> */}
        <ContainerIntro
        title="What is Mobile App?"
     
        description="The Mobile App is an android and iOS programme that connects kids, parents, instructors, and school administration to facilitate quicker communication. Parents may use the school mobile app to immediately monitor their children's activities at school/college. Students can use it to check their assignments, teachers can offer direct feedback, and management can use it to directly obtain information about the day's collection, dues receipts, and other things. We are India's most renowned provider of school ERP and mobile apps with  customisable personalised logo for schools/colleges and more than 1 lakh loyal users."
      />
      <ContentTemplate description={Mobileapp.subtitle} image={Mobileapp.subimage} isReverse={false} />
      <ContentTemplate description={Mobileapp.subtitleone} image={Mobileapp.subimageone} isReverse={true} />
      <ContentTemplate description={Mobileapp.subtitletwo} image={Mobileapp.subimagetwo} isReverse={false} />
      <SectionThree  content={content}/>
      <MobileSectionTwo/>
      </div>
    );
};
export default  MobileappSerives;