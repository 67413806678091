import './WhyGenericCard.css'
import { motion } from 'framer-motion'



const WhyGenericCard = ({ content }) => {
    return (
        <div className="WhyGenericCard-main-container">
            <div className="WhyGenericCard-left-container">
                <div className="WhyGenericCard-left-title">
                    {content.title}
                </div>
                <div className="WhyGenericCard-left-description">
                    {content.description}
                </div>
                {/* <div className="WhyGenericCard-left-contact-info">
                    Connect with us
                </div> */}
            </div>
            <div className="WhyGenericCard-right-container">
                <motion.img
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1, transition: { duration: 2 } }}
                    src={content.image}
                    alt=""
                    className="WhyGenericCard-right-image"
                />
            </div>
        </div>
    )
}
export default WhyGenericCard