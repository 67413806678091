import "./About.css";
import ContentTemplate from "../../Components/contentTemplate/ContentTemplate";
import LandingSection from "../../Components/LandingSection/LandingSection";
import {about_heroSection_Content as AboutContant} from "../../Model/HeroSection.content"
import React, { useEffect, useState } from "react";
import Timeline2 from "../../Assets/timeline/timeline2.png"
import Timeline1 from "../../Assets/timeline/timeline.svg"
import { TabMenu } from 'primereact/tabmenu';


const About = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
  const [Contents, setContents] = useState(true)

  const items = [
    {label: 'Implementation Strength', command:(event)=>{setContents(true)}},
    {label: 'Technical strength',command:(event)=>{setContents(false)}},
  ];

  return (
    <div className="About-main-container">

      <LandingSection Background={AboutContant.image} Title={AboutContant.title} Description={AboutContant.description} />
      <ContentTemplate description={AboutContant.subtitle} image={AboutContant.subimage} isReverse={false} />
      <ContentTemplate description={AboutContant.subtitleone} image={AboutContant.subimage} isReverse={true} />
      <ContentTemplate description={AboutContant.subtitletwo} image={AboutContant.subimage} isReverse={false} />
      {/* <div className="About-Text-content">
        <div className="about-text-paragraph">
          <p>Welcome to our School ERP platform, where we are dedicated to transforming education management.</p>
          <p>Our comprehensive solutions empower educational institutions to streamline administrative tasks, automate processes, and enhance communication between stakeholders. From student admissions and attendance tracking to grade management and resource allocation, our ERP system offers a seamless and efficient approach to school management.</p>
          <p>With intuitive interfaces and robust features, we provide a user-friendly experience for teachers, administrators, parents, and students alike. Our goal is to simplify school operations, improve collaboration, and facilitate data-driven decision-making. Join us in revolutionizing the educational landscape with our innovative School ERP solutions.</p>
        </div>

      </div> */}
      <div className="AboutUs-button-container">
        <TabMenu model={items} />
      </div>

      <div className="AboutUs-content-container">
        {
          Contents ? 
          <ul className="_AboutUs-content-title">
          <li>Data import from Excel for students &staff.</li>
          <li>Process understanding &gap analysis and alignment with ERP.</li>
          <li>Implementations, feedback, and reviews. Multiple support bases and modes available.</li>
          <li>Training for assigned users time to time.</li>
          <li>Continuous upgrades in the software and apps.</li>
          <li>Customization of reports as per school formats suitability.</li>
          <li>Step-by-step hand holding and training as you go Android apps available and exclusive iOS app.</li>
        </ul>
          :
          <ul className="_AboutUs-content-title">
          <li>24×7×365 access and availability through internet.</li>
          <li>Ability to work on your favourite browser.</li>
          <li>Integrated connection platforms (biometrics, website, ERP, Bank Account, and mobile app).</li>
          <li>Multiple device support (desktop, laptop, and mobile) User-based dashboard and access.</li>
          <li>Modules and rights assignment and control by management.</li>
          <li>Paperless school.</li>
          <li>Single database and session-wise availability & access for each module.</li>
        </ul>
        }
          
      </div>
      

    </div>
  );
};


export default About;