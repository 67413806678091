import "./WhyGeneric.css"
import ContainerIntro from "../../../Components/ContainerIntro/ContainerIntro"
import { TabMenu } from 'primereact/tabmenu';
import WhyGenericCard from "../../../Components/WhyGenericCard/WhyGenericCard";
// import content from "../../../Model/whygeneric.content.json"
import { useState } from "react";
import {why_generic_title,why_generic_content} from "../../../Model/Home.content"

const WhyGeneric = () => {
  const [Contents, setContents] = useState(why_generic_content[0])
  
  const items = [
    {label: 'Interface', icon: 'pi pi-fw pi-home', command:(event)=>{setContents(why_generic_content[0])}},
    {label: 'Security', icon: 'pi pi-fw pi-calendar',command:(event)=>{setContents(why_generic_content[1])}},
    {label: 'Support', icon: 'pi pi-fw pi-pencil', command:(event)=>{setContents(why_generic_content[2])}},
    {label: 'Accessibility', icon: 'pi pi-fw pi-file', command:(event)=>{setContents(why_generic_content[3])}},
    {label: 'Migration', icon: 'pi pi-fw pi-cog', command:(event)=>{setContents(why_generic_content[4])}},
    {label: 'Communication', icon: 'pi pi-fw pi-phone', command:(event)=>{setContents(why_generic_content[5])}},
    {label: 'Paperless', icon: 'pi pi-fw pi-copy', command:(event)=>{setContents(why_generic_content[6])}}
];

  return (
    <div className="WhyGeneric-main-container">
      <ContainerIntro 
      title={why_generic_title.title}
      description={why_generic_title.description}
      />

      <div className="WhyGeneric-button-container">
        <TabMenu model={items} />
      </div>

      <div className="WhyGeneric-Card-container">
        <WhyGenericCard content={Contents}/>
      </div>
    </div>
  )
}
export default WhyGeneric