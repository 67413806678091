import "./Sectionone.css";
// import blob from "../../../Assets/landingPage/blob.png"
import blob from "../../Assets/landingPage/blob.png";
import hexagon from "../../Assets/landingPage/hexagon.png";
// import ContentTemplate from "../contentTemplate/ContentTemplate";

const SectionOne = ({ title, description, image, subtitle, subimage }) => {
  return (
    <>
      <section className="SectionOne-wrapper">
        <img className="floater" src={blob} alt="blob" />
        <img className="rotator" src={hexagon} alt="blob" />
        <div className="SectionOne-Details-Container">
          <div
            style={{
              width: "60%",
              gap: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="sectionOne-title">{title}</div>
            <div className="description">{description}</div>
            <div className="main-image">
              <img src={image} alt="" />
              {/* {image} */}
            </div>
          </div>
        </div>
        {/* <div className="subtitle">{subtitle}</div>
            <div className="subimage">{subimage}</div> */}
      </section>



    </>
  );
};
export default SectionOne;
