import "./Elearning.css";
// import SectionOne from "../../../Components/Sectionone/Sectionone";
// import logo from "../../../Assets/landingPage/carousel-3.png";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import { services_heroSection_Content } from "../../../Model/HeroSection.content"
import SchoolERPImage from "../../../Assets/landingPage/SchoolERP.jpg"
import LandingSection from "../../../Components/LandingSection/LandingSection";
import { useEffect } from 'react';
import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";
import { Accordion, AccordionTab } from 'primereact/accordion';

const Elearning = () => {

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
  const ElearingContant = services_heroSection_Content.Elearning

  // const ElearningTwo={
  //   subtitle:"Virtual classrooms and interactive multimedia tools enhance engagement and provide a dynamic learning experience. Furthermore, e-learning breaks down geographical barriers, allowing individuals from all over the world to connect and collaborate. Whether you're a student seeking to expand your skills or a professional aiming to upskill, e-learning empowers you to acquire knowledge and achieve personal and professional growth on your terms.",
  //   subimage:"https://img.freepik.com/free-photo/online-communication_1098-15842.jpg?t=st=1684927961~exp=1684928561~hmac=a0c65a2c07cc956459cd71fc26ffdc3e4b91dc9f44e8b21feddc4ba76e4c04d8"
  // }
  return (
    <div className="Elearning-main-container">
      <LandingSection Background="https://images.pexels.com/photos/4064845/pexels-photo-4064845.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" Title={ElearingContant.title} Description={ElearingContant.description} />
      <ServiceMenu/>
      <ContentTemplate description={ElearingContant.subtitleone} image={ElearingContant.subimageone} isReverse={false} />
      {/* <ContentTemplate description={ElearingContant.subtitletwo} image={ElearingContant.subimagetwo} isReverse={true} /> */}
      <div className="Sectionfourmain">

        <div className="sectionfour-left">

          <div className="sectionfour-text">
            <div className="SectionFourHead-title">Benefits of E-Learning Software for School/Colleges</div>
            <Accordion activeIndex={0}>
              <AccordionTab header="Resources are accessible at all times and places">
                <p className="m-0">
                  All that is needed for convenient access to web-based classes at any time or place is reliable internet connectivity and effective e-learning software. For schools, EduAppz guarantees trouble-free e-learning tools so that teaching and learning may go without hindrance. In order to stay one step ahead of the competition, learning may now be extended outside the classroom to include the weekends and vacations.
                </p>
              </AccordionTab>
              <AccordionTab header="Alerts and permits direct connection to live classes">
                <p className="m-0">
                  Reliable e-learning software has a number of useful features. Connecting to the planned class on time is the most crucial factor to take into account for live online classes. A key component of E-connect is the e-learning software called EduAppz. By sending pertinent links to actual live lessons being held via Zoom classes or Google Meet, this powerful tool enables the student to connect directly to the online sessions. Additionally, the app alerts users of upcoming lessons so that they may prepare and bring the necessary materials without missing a beat</p>
              </AccordionTab>
              <AccordionTab header="Access to the Resources Needed Multiple Times">
                <p className="m-0">
                  When we talk about classroom instruction, the learning is only available within a specific period of time. Things improve when the same instruction is delivered via a digital medium. Learning might be accomplished in an advanced and participatory manner using electronic media. The learner can access the necessary information as many times as necessary with the aid of e-learning. The fundamental benefit of e-learning solutions is that they eliminate student-related issues like misplaced study materials, wear and tear on books, and other issues that might impede academic advancement.</p>
              </AccordionTab>
              <AccordionTab header="Resources Come in a Variety of Formats">
                <p className="m-0">
                  The first image that springs to mind when we consider classroom instruction is the instructor lecturing the class on the subject. And in a few rare instances, a model with weak existence validity is used in the explanation. The entire situation is changed by using the greatest e-learning software for schools/colleges. It eliminates the restrictions of traditional classroom instruction and aids in extending learning.</p>
              </AccordionTab>
              <AccordionTab header="Instant quiz results are also available">
                <p className="m-0">
                  Utilising effective online classroom software for academic purposes enables the incorporation of beneficial elements like the administration of online examinations or quizzes. It is useful to assess students' comprehension of a specific course or topic so that future lessons may be arranged appropriately. The app reminds users of any quizzes scheduled by the teacher of the class. Additionally, the results of the quiz are immediately shown after completion, enabling the learner to assess their current development.</p>
              </AccordionTab>
              <AccordionTab header="Discussion Platform for Teacher and Students">
                <p className="m-0">
                  Many questions and uncertainties arise along with the explanation of the chapter's themes when classes are held online. There is occasionally a risk that the questions won't be addressed in the allotted time for the lesson.
                  The function of the conversation dashboard has been introduced to deal with such situations. This makes it possible for the teacher and student to converse on the same platform and go through necessary subjects.</p>
              </AccordionTab>
              <AccordionTab header="Safe and Effective Learning Method">
                <p className="m-0">
                  There are a lot of materials available online if we talk about them. However, learning might be done in a perfect method utilising the school's e-learning software. The lecturers of the classes can post materials for the students in a variety of formats. so that people may conveniently get the necessary resources in a single location. Students may review the best among the others in one location rather than scrolling through the numerous. In addition to this, discussing the costs associated with the study materials' accessible resources in the form of paper may result in harm to them. Smart e-learning classroom software not only reduces costs but also guarantees trouble-free learning.</p>

              </AccordionTab>
              {/* <AccordionTab header="Smart way to conduct assessments +">
            <p className="m-0">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
        </AccordionTab>
        <AccordionTab header="Smart way to conduct assessments +">
            <p className="m-0">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
            </p>
        </AccordionTab> */}
            </Accordion>
          </div>
        </div>
        {/* <div className="sectionfour-right">
  <div className="sectionfour-right-image">
    <h1>What is Mobile app </h1>
    <p className="m-1">
    The " Mobile App" is an android and iOS programme that connects kids, parents, instructors, and school administration to facilitate quicker communication. Parents may use the school mobile app to immediately monitor their children's activities at school/college. Students can use it to check their assignments, teachers can offer direct feedback, and management can use it to directly obtain information about the day's collection, dues receipts, and other things.
We are India's most renowned provider of school ERP and mobile apps with  customisable personalised logo for schools/colleges and more than 1 lakh loyal users.

    </p>
    {  <motion.img 
          initial={{ scale: 0 }}
          whileInView={{ scale: 1, transition: { duration: 2 } }}
          className="AppMockUp" 
          src={AppMockUP} 
          alt="" 
          /> }
  </div>
</div> */}
      </div>
    </div>
  );
};
export default Elearning;