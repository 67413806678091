import Parent from "../Assets/icons/parents.png";
import Management from "../Assets/icons/management.png"
import Teacher from "../Assets/icons/teacher.png"
import Student from "../Assets/icons/student.png"

const content=[
    {
        "id" : 1,
        "image":Parent,
        "title": "Parents",
        "description":"Effortlessly access school information and manage tasks like fees, progress reports, and admissions through a single platform, eliminating the need for on-site visits. Stay updated on ward's developments via alerts. Connect with school staff and receive teacher feedback seamlessly. Online fee payment enhances efficiency and convenience, allowing quick submissions from anywhere, anytime. A comprehensive solution that streamlines parent-school communication and simplifies administrative processes.",
        "subimage":"https://img.freepik.com/free-photo/family-looking-together-laptop-home_23-2149126707.jpg?t=st=1684822520~exp=1684823120~hmac=73eea744061864dd8dd2a9cc9f7524cf578cacf20f937bc67c62cc0c7fbdb1b3"
    
    },
    {
        "id" : 2,
        "image":Student,
        "title": "Students",
        "description":"A premier educational software facilitates convenient schedule checks, assignment submissions, and exam reminders. It enables enriched e-learning through varied media resources, such as presentations, audio, and video lessons, fostering better understanding. Discussion boards encourage post-class interactions and query resolutions. Password recovery ensures seamless access for students. This comprehensive tool empowers holistic learning beyond traditional classrooms, providing a seamless blend of administrative support and interactive study resources.",
        "subimage":"https://img.freepik.com/free-photo/online-communication_1098-15842.jpg"
    },
    {
        "id" : 3,
        "image":Teacher,
        "title": "Teachers",
        "description":"The user-friendly dashboard allows swift class or individual student performance evaluation. This software facilitates seamless assessment across various subjects, ensuring efficient lesson planning. Teachers conduct quizzes, provide audio/video explanations, and share live course links for immediate student engagement. Vital student data, like contact info and health details, are readily accessible. Subject experts input and manage evaluation results, enhancing communication with parents. This comprehensive tool streamlines teaching, from real-time interaction to result management, fostering effective learning and teacher-parent engagement.",
        "subimage":"https://img.freepik.com/free-photo/portrait-female-teacher-holding-notepad-green_140725-149620.jpg?t=st=1684828158~exp=1684828758~hmac=a97c4c7e3f266191d45b5488e484777e88db8f4484f00bfb34bec4ca15f7d48a"
    },
    {
        "id" : 4,
        "image":Management,
        "title": "Management",
        "description":"Streamline daily administrative tasks effortlessly with an interactive dashboard for crucial data analysis. High-ranking officials access student-staff attendance stats, aiding decisions. The fee dashboard showcases collections and debts, while a reliable payroll module ensures precise wage processing and documentation. Vital functions like TC generation and loan management are seamlessly integrated, ensuring efficient procedures.",
        "subimage": "https://img.freepik.com/free-photo/smiling-business-lady-working-with-colleagues_1262-2153.jpg?t=st=1684822162~exp=1684822762~hmac=256133caf229babc6e6326a54391edca838ad84f5e5aa50af55fa3b78cdf4105"
    }
    
]


export default content;



export const School_Erp_title={
    title: "EDUCATIONAL ERP",
    description:"Generic Software Solutions has created one of the most cutting-edge and user-friendly Educational ERP Software.",
    image: "https://img.freepik.com/free-photo/excited-students-with-laptops-textbooks-preparing-test-sitting-floor-indoor-portrait-international-friends-studying-together-before-exams_197531-3817.jpg?t=st=1684997473~exp=1684998073~hmac=55f2b6cb037405fe92b55db4076e9c677996ecf1ab1cc6b933cf2034f2a483e3",
    subtitle: "The Admission Counsellor’s needs for streamlining the entire admission process are all met by this school's ERP system. In order to effectively communicate with parents from various regions, School ERP One has a multilingual option and the ability to instantly generate circulars and SMS. The application includes a teacher's calendar that includes lesson plans, schedules, and status information that the relevant authorities can view at any time for any teacher who is currently teaching a class. The application gives the responsible authorities the option to create job requests with the option of having the key decision-maker approve them.The school can create and print customised Report Cards using our School ERP based on their needs. Report Cards can also be published online and via a mobile application.",
    // subimage: "https://img.freepik.com/free-photo/science-dna-research-development-human_53876-121145.jpg?t=st=1684997614~exp=1684998214~hmac=c314d164cdc5bb5279be9c8b845adeb29113aa00540f51a740ccc73ae28de9c",
    subtitleone: "Additionally, our ERP can offer solutions for fee management because it automates the process of approving fee concessions and waivers and sends reminders for past-due fees. The application might also give students an easy way to manage their homework and assignments. The application can also be used to manage the inventory of different school supplies like books, uniforms, and diaries.Additionally, the school can manage employee and student attendance as well as all other activities through the use of the school's ERP system, which has the ability to manage a variety of information systems, including employee and student information systems. Some of the most well-known Organisations in India have recognised Generic ERP School ERP Software as the Best School ERP in India.",
    // subimageone: "https://img.freepik.com/free-photo/online-communication_1098-15842.jpg",

}

