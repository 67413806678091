import "./CollegeManagement.css"

import SectionOne from "../../../Components/Sectionone/Sectionone";
// import logo from "../../../Assets/landingPage/carousel-2.png";
import SectionThree from "../../../Components/SectionThree/SectionThree";
import content from "../../../Model/ErpSection.js";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import {services_heroSection_Content} from "../../../Model/HeroSection.content"
import LandingSection from "../../../Components/LandingSection/LandingSection";
import { useEffect } from 'react';
import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";

const College =()=>{
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
    const Collegemanagement = services_heroSection_Content.CollegeManagement
     
    return(
        <div className="collegemanagement-main-container">
      <LandingSection Background="https://images.pexels.com/photos/1205651/pexels-photo-1205651.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" Title={Collegemanagement.title} Description={Collegemanagement.description} />

   
      {/* <SectionOne
        title={Collegemanagement.title}
        description={Collegemanagement.description}
        image={Collegemanagement.image}
        subtitle={Collegemanagement.subtitle}
        subimage={Collegemanagement.subimage}
      /> */}
      <ServiceMenu/>
      <ContentTemplate description={Collegemanagement.subtitle} image={Collegemanagement.subimage} isReverse={false} />
      <ContentTemplate description={Collegemanagement.subtitleone} image={Collegemanagement.subimageone} isReverse={true} />
      <ContentTemplate description={Collegemanagement.subtitletwo} image={Collegemanagement.subimagetwo} isReverse={false} />
      <SectionThree  content={content}/>
      </div>
    );
};
export default College;