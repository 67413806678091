import "./AboutUs.css";
import ContainerIntro from "../../../Components/ContainerIntro/ContainerIntro";
import React, { useEffect, useState } from "react";
import { Timeline } from "primereact/timeline";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { motion } from 'framer-motion';
import { Chrono } from "react-chrono";
import { TabMenu } from 'primereact/tabmenu';
import Timeline1 from "../../../Assets/timeline/timeline.svg"
import Timeline2 from "../../../Assets/timeline/timeline2.png"
import {home_Aboutus} from "../../../Model/Home.content"



const AboutUs = () => {
  const [Contents, setContents] = useState(true)
  // const title = home_Aboutus

  // const items = [
  //   {
  //     id: 1,
  //     title: "2018",
  //     icon: AcUnitIcon,
  //     iconColor: "#8cd98c",
  //     cardTitle: "Foundation Year",
  //     url: "http://www.history.com",
  //     cardSubtitle:
  //       "Define your purpose.",
  //     cardDetailedText:
  //       "Decide between trust.",
  //     imageUrl:
  //       "https://drive.google.com/uc?id=1NP4pvjMG38OWMq9jMGlYiFDPiiVgoHOd",
  //   },
  //   {
  //     id: 2,
  //     title: "2019",
  //     icon: AcUnitIcon,
  //     iconColor: "#79d279",
  //     cardTitle: "Onboarded 150+ Schools",
  //     url: "http://www.history.com",
  //     cardSubtitle:
  //       "Students have pushed schools and universities towards net zero all over the world. ",
  //     cardDetailedText:
  //       "Men of the British Expeditionary Force (BEF) wade out to..",
  //     imageUrl:
  //       "https://drive.google.com/uc?id=1ry6S8EcGFtwaPN7LkqT_jdSPkIKI8Uim",
  //   },
  //   {
  //     id: 3,
  //     title: "2020",
  //     icon: AcUnitIcon,
  //     iconColor: "#66cc66",
  //     cardTitle: "Reached to 350+ Progressive Schools",
  //     url: "http://www.history.com",
  //     cardSubtitle:
  //       "ONBOARD SCHOOL INFO AND TRAINING · Logging In · Permissions.",
  //     cardDetailedText:
  //       "Men of the British Expeditionary Force (BEF) wade out to..",
  //     imageUrl:
  //       "https://drive.google.com/uc?id=1veLyF63IvEp1qCU7OT3rld3YFG_NwpnV",
  //   },
  //   {
  //     id: 4,
  //     title: "2021",
  //     icon: AcUnitIcon,
  //     iconColor: "#53c653",
  //     cardTitle: "Crossed International Boundaries ",
  //     url: "http://www.history.com",
  //     cardSubtitle:
  //       "Cross-border data flows are an increasingly essential element of international trade",
  //     cardDetailedText:
  //       "Men of the British Expeditionary Force (BEF) wade out to..",
  //     imageUrl:
  //       "https://drive.google.com/uc?id=11UGm5XvE0qcRsGarosT0GZjg4p_4wsA5",
  //   },
  //   {
  //     id: 5,
  //     title: "2022",
  //     icon: AcUnitIcon,
  //     iconColor: "#40bf40",
  //     cardTitle: "Technology shifted to AWS-Amazon Web Services.",
  //     url: "http://www.history.com",
  //     cardSubtitle:
  //       " Is a subsidiary of Amazon that provides on-demand cloud computing platforms and APIs..",
  //     cardDetailedText:
  //       "Men of the British Expeditionary Force (BEF) wade out to..",
  //     imageUrl:
  //       "https://drive.google.com/uc?id=1veLyF63IvEp1qCU7OT3rld3YFG_NwpnV",
  //   },
  //   {
  //     id: 6,
  //     title: "2023",
  //     icon: AcUnitIcon,
  //     iconColor: "#39ac39",
  //     cardTitle: "Current Vision Education World.",
  //     url: "http://www.history.com",
  //     cardSubtitle:
  //       "All students are empowered to learn and achieve, experiencing high quality teaching practice",
  //     cardDetailedText:
  //       "Men of the British Expeditionary Force (BEF) wade out to..",
  //     imageUrl:
  //       "https://drive.google.com/uc?id=123sb7BpgVJVQ9sd_-bOYavcyGIBQSqrB",
  //   },
  // ];

  const items = [
    {label: 'Our Journey', command:(event)=>{setContents(true)}},
    // {label: 'Tech Journey',command:(event)=>{setContents(false)}},
  ];

  const customizedMarker = (item) => {
    return (
      <span
        className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: item.iconColor }}
      >
        <i className={item.icon}></i>
      </span>
    );
  };

  const CustomizedContent = (item) => {
    const CardMotion = motion(Card);
    return (
      <CardMotion
        initial={{ x: item.id % 2 === 0 ? -200 : 200 }}
        whileInView={{ x: 0, transition: { duration: 2 } }}
        title={item.cardTitle}
        subTitle={item.title}>
        {item.imageUrl && (
          <img
            src={item.imageUrl}
            alt={item.url}
            width={200}
          // className="shadow-1"
          />
        )}
        <p>{item.cardSubtitle}</p>
        {/* <Button label="Read more" className="p-button-text"></Button> */}
      </CardMotion>
    );
  };

  return (
    <div className="AboutUs-main-container">
      <ContainerIntro
        title={home_Aboutus.title}
     
        description={home_Aboutus.description}
      />

      <div className="AboutUs-button-container">
        <TabMenu model={items} />
      </div>

      <div className="AboutUs-Card-container">
        <img className="timeline" src={Contents ? Timeline1:Timeline2} alt="" />
        {/* <Timeline
          value={items}
          align="alternate"
          className="customized-timeline"
          marker={customizedMarker}
          content={CustomizedContent}
        /> */}
        {/* <Chrono
          items={items}
          mode="HORIZONTAL"
          showAllCardsHorizontal
          cardWidth={450}
          cardHeight={300}
          contentDetailsHeight={100}
          mediaHeight={300}
          mediaSettings={{ fit: 'cover' }}
          fontSizes={{
            title: "1rem"
          }}
          theme={{
            primary: '#537324',
            secondary: '#2D4010',
            cardBgColor: '#FFFFFF',
            titleColor: '#2D4010',
            titleColorActive: '#FFFFFF',
            cardTitleColor: '#2D4010',
            cardSubtitleColor: '#537324'
          }}
          slideShow
        /> */}
      </div>
    </div>
  );
};
export default AboutUs;
