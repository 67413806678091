import "./ServiceMenuItem.css";
import { Menubar } from "primereact/menubar";
import { useNavigate, useLocation } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
import { useState, useEffect } from "react";


const ServiceMenu=()=>{
    const navigate = useNavigate();
    const location = useLocation();

    
    const handleButtonClick = (index) => {
            console.log("same link");
            localStorage.setItem("index", index);
            navigate(items[index-1].page, { replace: true });
      };


      const CustomButton = ({ title, index, isActive, handleClick }) => {
        return (
          <div
            className={`contact-menu-button ${isActive ? '_active' : ''}`}
            onClick={() => {
                handleClick(index);
            }}
          >
            {title}
          </div>
        );
      };


    const items = [
        {
          id:1,
          label: "School Management Software",
          page: siteMap.SchoolmanagementPage.path
        },
        {
            id:2,
            label: "College Management Software",
             page: siteMap.CollegemanagementPage.path
          },
          {
            id:3,
            label: "Industry Management Software",
            page: siteMap.IndutrialmanagementPage.path
          },
          {
            id:4,
            label: "Mobile Apps",
            page: siteMap.MobileappServicePAge.path
          },
          {
            id:5,
            label: "E-Learning Software",
            page: siteMap.ElearningPage.path
          },
          {
            id:6,
            label: "Website Design",
            page: siteMap.WebsitePage.path
          },
          {
            id:7,
            label: "Biometric/RFID System",
            page: siteMap.RfidPage.path
          },      
        
      ];


      return(
        <div className="ServiceMenu-main-container">
         {items.map((item, index) => {
           return <CustomButton 
           key={index}
           title={item.label} 
           index={item.id} 
           isActive={location.pathname === item.page}
           handleClick={handleButtonClick}
            />;
          })}

        
      </div>
      );
};
export default ServiceMenu;