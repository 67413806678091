import "./ClientLogo.css"
import React from 'react'

function ClientLogo({images}) {
  return (
    <div className="ClientLogo_main-container">
    {images.map(image=>{
      return  <div className="clientLogo-main-image-box">
            <img draggable={false} className="ClientLogo-image" src={image} alt="" />
        </div>
    })
    }
</div>
  )
}

export default ClientLogo