import "./Home.css"
import Landing from "./Landing/Landing"
import Service from "./Service/Service"
import AboutUs from "./AboutUs/AboutUs"
import PlaystorePost from "./PlaystorePost/PlaystorePost"
import WhyGeneric from "./WhyGeneric/WhyGeneric"
import ContactUs from "./ContactUs/ContactUs"
import SlideForm from "../../Components/SlideForm/SlideForm"
import TestimonialsCarousel from "./home_testimonials/home_testimonials"
import VideoCarousel from "../../Components/VideoCarousel/VideoCarousel"
import VideoBox from "../../Components/VideoBox/VideoBox"


const Home = () => {
  return (
    <div className="Home-main-container">
      <SlideForm />
      {/* <Landing/> */}

      <VideoBox />
      {/* < VideoCarousel/> */}

      <Service />
      <AboutUs />
      <WhyGeneric />
      <PlaystorePost />
      <TestimonialsCarousel />
      <ContactUs />
    </div>
  )
}
export default Home