import { Routes, Route } from "react-router-dom";
import { siteMap } from "./SiteMap";

import Home from "../Pages/Home/Home";
import Erp from "../Pages/ERP/Erp";
import Websites from "../Pages/Services/Websites/Websites";
import SchoolManagement from "../Pages/Services/SchoolManagement/Schoolmanagement";
import Seo from "../Pages/Services/SEO/Seo";
import Elearning from "../Pages/Services/Elearning/Elearning";
import Rfid from "../Pages/Services/Rfid/Rfid";
import Client from "../Pages/Clients/Clients";
import Contact from "../../src/Pages/Contactus/Contact"
// import About from "../../src/Pages/Services/About"
import About from "../Pages/About/About";
import BlogList from "../Pages/Blog/BlogList/BlogList";
import Blog from "../Pages/Blog/Blog";
import College from "../Pages/Services/CollegeManagement/CollegeManagement";
import IndustryManagement from "../Pages/Services/Industrymanagement/industrymanagement";
import MobileappService from "../Pages/Services/Mobileapp/Mobileapp";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path={siteMap.HomePage.path} element={<Home />} />
      <Route path={siteMap.ErpPage.path} element={<Erp/>}/>
      <Route path={siteMap.WebsitePage.path} element={<Websites/>}/>
      <Route path={siteMap.SchoolmanagementPage.path} element={<SchoolManagement/>}/>
      <Route path={siteMap.CollegemanagementPage.path} element={<College/>}/>
      <Route path={siteMap.IndutrialmanagementPage.path} element={<IndustryManagement/>}/>
      <Route path={siteMap.MobileappServicePAge.path} element={<MobileappService/>}/>
      <Route path={siteMap.SeoPage.path} element={<Seo/>}/>
      <Route path={siteMap.ElearningPage.path} element={<Elearning/>}/>
      <Route path={siteMap.RfidPage.path} element={<Rfid/>}/>
      <Route path={siteMap.ClientPage.path} element={<Client/>}/>
      <Route path={siteMap.ContactUsPage.path} element={<Contact/>}/>
      <Route path={siteMap.AboutPage.path} element={<About/>}/>
      <Route path={siteMap.BlogPage.path} element={<BlogList/>}/>
      <Route path={siteMap.BlogContentPage.path} element={<Blog/>}/>
    </Routes>
  );
};

export default PageRoutes;
