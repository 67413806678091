import "./PlaystorePost.css"
import ContainerIntro from "../../../Components/ContainerIntro/ContainerIntro";
import Googleplay from "../../../Assets/image/google-play-badge.png"
import Appstore from "../../../Assets/image/app-store-badge.png"
import AppMockUP from "../../../Assets/image/App-Mockup.png"
import Divider from '@mui/material/Divider';
import { motion } from 'framer-motion'

const PlaystorePost = () => {
  return (
    <div className="Playstore-post-main-container">
      <div className="Playstore-post-left-container">
          <motion.img 
          initial={{ scale: 0 }}
          whileInView={{ scale: 1, transition: { duration: 2 } }}
          className="AppMockUp" 
          src={AppMockUP} 
          alt="" 
          />
      </div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <div className="Playstore-post-right-container">
        <ContainerIntro
          title="EXPLORE OUR MOST ADVANCED"
          subtitle="Mobile App"
          description="Mobile App ensures that almost all the necessary work related to institute is done from anywhere and anytime with just a tap away."
        />
        <div style={
          {
            height:"", width:"100%", display:"flex", alignItems:"center",  justifyContent:"center", gap:"25px"
          }
        }>
          <img className="GooglePlayButton" src={Googleplay} alt="" onClick={()=>{window.location.replace("https://play.google.com/store/apps/details?id=com.genericsoftware.schoolappz&pcampaignid=web_share")}}/>
          <img className="GooglePlayButton" src={Appstore} alt="" />
        </div>
      </div>
    </div>
  )
}
export default PlaystorePost