import { useState } from "react";
import "./SectionThree.css";

const SectionThree = ({ content }) => {

  const [currentId, setcurrentId] = useState(1);

  

  const CustomImageBox = ({ id, image, title }) => {
    return (
      <div className="customBoxcontainer" onClick={()=>{
        console.log("CURRENT INDEX", id);
        setcurrentId(id)
      }}>
        <img src={image} alt="" />
        <div>{title}</div>
      </div>
    );
  };

  return (
    <div className="sectionThree-main">
      <div className="sectionThree-title">Role Based Portal Benifits</div>
      <div className="sectionThreeCenter-box">
        {content.map((item) => {
          return <CustomImageBox id={item.id} image={item.image} title={item.title} />;
        })}
      </div>
      <div className="SectionThreeSubtitle">
        <div className="SectionThreeBox-SubTitle">
          {content[currentId-1]?.description}
        </div>
        <div className="SectionThree-subimage">
          <div className="sectionhree-subimage-image">
            <img src={content[currentId-1]?.subimage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SectionThree;
