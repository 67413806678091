import "./Erp.css";
import SectionOne from "../../Components/Sectionone/Sectionone";
import ErpScetionTwo from "../ERP/erpSectionTwo/erpSectionTwo";
import SectionThree from "../../Components/SectionThree/SectionThree";
import content from "../../Model/ErpSection.js";
import { Accordion, AccordionTab } from 'primereact/accordion';
import ContentTemplate from "../../Components/contentTemplate/ContentTemplate";
import SchoolERPImage from "../../Assets/landingPage/SchoolERP.jpg"
import LandingSection from "../../Components/LandingSection/LandingSection";
import {School_Erp_title} from "../../Model/ErpSection.js";
import { useEffect } from 'react';

// import SchoolErp from "../../Assets/landingPage/carousel1-1.png"
const Erp = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
  const Erpcontent=School_Erp_title
  // const Erpcontent = {
  //   title={School_Erp_title}
  //   title: "EDUCATIONAL ERP",
  //   description: "Generic Solutions has developed one of the most advanced and user friendly Educational ERP Software for managing various institutional activities.",
  //   image: "https://img.freepik.com/free-photo/excited-students-with-laptops-textbooks-preparing-test-sitting-floor-indoor-portrait-international-friends-studying-together-before-exams_197531-3817.jpg?t=st=1684997473~exp=1684998073~hmac=55f2b6cb037405fe92b55db4076e9c677996ecf1ab1cc6b933cf2034f2a483e3",
  //   subtitle: "A School ERP (Enterprise Resource Planning) system is a comprehensive software solution designed to streamline and automate various administrative tasks within educational institutions. It integrates multiple modules, including student management, attendance tracking, timetable scheduling, grading, and communication. With a School ERP, schools can efficiently manage student records, track academic progress, generate reports, and facilitate communication between teachers, students, and parents. The system enhances operational efficiency, reduces paperwork, and provides real-time access to information. School ERPs play a crucial role in improving the overall management of educational institutions, promoting transparency, and enhancing collaboration among stakeholders.",
  //   subimage: "https://img.freepik.com/free-photo/science-dna-research-development-human_53876-121145.jpg?t=st=1684997614~exp=1684998214~hmac=c314d164cdc5bb5279be9c8b845adeb29113aa00540f51a740ccc73ae28de9c",
  // };
  // const [Contents, setContents] = useState(content[0]);

  return (
    <div className="Erp-main-container">
      {/* <SectionOne 
        title={Erpcontent.title}
        description={Erpcontent.description}
        image={Erpcontent.image}
        subtitle={Erpcontent.subtitle}
        subimage={Erpcontent.subimage}
        
      /> */}

      <LandingSection Background={SchoolERPImage} Title={Erpcontent.title} Description={Erpcontent.description} />

      <ContentTemplate description={Erpcontent.subtitle} image={Erpcontent.subimage} isReverse={false} />

      <ContentTemplate description={Erpcontent.subtitleone} image={Erpcontent.subimageone} isReverse={true} />
      <ErpScetionTwo />
      <SectionThree content={content} />
      {/* <div className="Sectionfourmain">

        <div className="sectionfour-left">

          <div className="sectionfour-text">
            <div className="SectionFourHead-title">Benifits Of Generic Solution</div>
            <Accordion activeIndex={0}>
              <AccordionTab header="Smart way to conduct assessments ">
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </AccordionTab>
              <AccordionTab header="Smart way to conduct assessments ">
                <p className="m-0">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                  quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                  sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                  Consectetur, adipisci velit, sed quia non numquam eius modi.
                </p>
              </AccordionTab>
              <AccordionTab header="Smart way to conduct assessments ">
                <p className="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                  quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                  mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                  Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                </p>
              </AccordionTab>
              <AccordionTab header="Smart way to conduct assessments ">
                <p className="m-0">
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                  quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                  mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                  Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                </p>
              </AccordionTab>
              {/* <AccordionTab header="Smart way to conduct assessments +">
                    <p className="m-0">
                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                        quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                        mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                    </p>
                </AccordionTab>
                <AccordionTab header="Smart way to conduct assessments +">
                    <p className="m-0">
                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                        quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                        mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                    </p>
                </AccordionTab> }
            </Accordion>
          </div>
        </div>
        <div className="sectionfour-right">
          <div className="sectionfour-right-image">
            <img src="https://qacraft.com/wp-content/uploads/2022/03/ERP-Software-knowledge-for-testers.png" alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Erp;
