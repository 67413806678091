import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
// import { motion } from 'framer-motion';
import "./ServiceCard.css";
export default function ServiceCard(service) {
  // const [isHovered, setIsHovered] = useState(false);
  // const CardMotion = motion(Card);
  const mobile_res = window.screen.width <= 720

  const cardWidth = !mobile_res ? "20vw" : "80vw"
  const cardHeight = !mobile_res ? "50vh" : "fit-content"

  return (
    <Card sx={{ maxWidth: cardWidth, minHeight:cardHeight, border:"0.5px solid", borderColor:"grey", cursor:"pointer", "&:hover": {borderColor: "#b7fa99", border:"2px solid" } }} 
    // initial={{ scale: 0.5 }}
    // whileInView={{ scale: 1, transition: { duration: 2 } }}
    >
      {/* <CardActionArea sx={{"&:hover": {backgroundColor: "transparent" }}}> */}
        <CardMedia
          component="img"
          height="140"
          image={service.image}
          alt="green iguana"
        />
        <CardContent sx={{"&:hover": {backgroundColor: "transparent" }}}>
          <Typography  variant="h5" component="div" color="#2D4010">
            {service.title}
          </Typography>
          <Typography variant="body2" color="#2D4010">
            {service.description}
          </Typography>
        </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}