// OUR SERVICES ASSETS
import Student from "../Assets/image/pages/home/ourServices/schoolerp.jpg"
import inventory from "../Assets/image/pages/home/ourServices/inventory.jpg"
import Library from "../Assets/image/pages/home/ourServices/library.jpg"
import Attendance from "../Assets/image/pages/home/ourServices/attendance.jpg"
import Payroll from "../Assets/image/pages/home/ourServices/payroll.jpg"
import Registration from "../Assets/image/pages/home/ourServices/registration.jpg"
import Admission from "../Assets/image/pages/home/ourServices/admission.jpg"
import Transport from "../Assets/image/pages/home/ourServices/transport.svg"
import FeeCollection from "../Assets/image/pages/home/ourServices/feecolllection.png"
import HumanResources from "../Assets/image/pages/home/ourServices/humanresources.jpg"
import PaymentGateway from "../Assets/image/pages/home/ourServices/paymentgetway.jpg"
import Barcode from "../Assets/image/pages/home/ourServices/barcode.jpg"
import BusinessWhatsApp from "../Assets/image/pages/home/ourServices/bw.jpg"
import RFID from "../Assets/image/pages/home/ourServices/rfid.jpg"
import TextSMS from "../Assets/image/pages/home/ourServices/textsms.jpg"
import Punch from "../Assets/image/pages/home/ourServices/punchin.jpg"

import user from "../Assets/image/pages/whyGenric/userfriendly.jpg"
import security from "../Assets/image/pages/whyGenric/datasecurity.jpg"
import Support from "../Assets/image/pages/whyGenric/supprot.jpg"
import Anytime from "../Assets/image/pages/whyGenric/anytime.jpg"
import Migration from "../Assets/image/pages/whyGenric/datamigration.png"
import Transfer from "../Assets/image/pages/whyGenric/Easyandfaster.png"
import Paper from "../Assets/image/pages/whyGenric/paperlesswork.jpg"

// import testimonial1 from "../../Assets/Testimonials/testimonia-1.png"
// import testimonial2 from "../../Assets/Testimonials/testimonia-2.png"
// import testimonial3 from "../../Assets/Testimonials/testimonia-3.png"
// import testimonial4 from "../../Assets/Testimonials/testimonia-4.png"

export const home_our_service_title=
{
        title:"OUR SERVICES",
        subtitle:"What can we do for you?",
        description:"Unlock seamless integration, real-time data insights, and optimized workflows with our customizable ERP software tailored to your unique business needs.",
}




export const home_our_service = [

    {
        "id" : 1,
        "title" : "Institute ERP",
        "services" : [
            {
                "id" : 1,
                "image" : Student,
                "title" : "Result",
                "description" : "Optimize HR: Recruit, onboard, train, track attendance. Our ERP HR software enhances workforce management, streamlining processes for efficiency."
            },
            {
                "id" : 2,
                "image" : inventory,
                "title" : "Inventory",
                "description" : "Our ERP software enables real-time inventory tracking, integration with barcode and RFID technology, and customizable reports for accurate data capture and management. It also offers insights into inventory performance"
            },
            {
                "id" : 3,
                "image" : Library,
                "title" : "Library",
                "description" : "Integrate library modules, student info, admin for data exchange. Borrowing, returns, fines, damaged/lost book payments managed in educational institutions."
            }
            ,{
                "id" : 4,
                "image" : Attendance,
                "title" : "Attendance",
                "description" : "Real-time attendance, analytics, leave management, ERP integration. Track trends, act with real-time updates, reports for comprehensive institutional management."
            },
            {
                "id" : 5,
                "image" : Payroll,
                "title" : "Payroll",
                "description" : "ERP integrates time tracking for precise attendance, leave, HR, accounting, ensuring data accuracy, scalability, and streamlined business processes."
            },
            {
                "id" : 6,
                "image" : Registration,
                "title" : "Registration",
                "description" : "Automated student alerts, SI system integration, data accuracy, no duplicates. Admin training, tech support included."
            },
            {
                "id" : 7,
                "image" : Admission,
                "title" : "Admission",
                "description" : "ERP simplifies admissions: document handling, fees, customization, application, payment, support, verification, communication for efficient school/college admission processes."
            }
            ,{
                "id" : 8,
                "image" : Transport,
                "title" : "Transport",
                "description" : "Our ERP software optimizes bus routes based on student addresses, stops, and traffic conditions, reducing travel time and managing student information, including attendance tracking."
            },
            {
                "id" : 9,
                "image" : FeeCollection,
                "title" : "Fee Collection",
                "description" : "The fee module is crucial for managing and processing fees, charges, and payments in software systems, particularly in financial transactions, billing, invoicing, and fee collection."
            },
         {
                "id" : 10,
                "image" : HumanResources,
                "title" : "Human Resources",
                "description" : "Streamline HR with recruiting, onboarding, training, attendance tracking. Enhance workforce management with our comprehensive ERP software for human resources."
            }
        ]
    },
    {
        "id" : 2,
        "title" : "Mobile App",
        "services" : [
           
         {
                "id" : 1,
                "image" : "https://cdn.pixabay.com/photo/2018/10/22/18/02/teacher-3765909_960_720.jpg",
                "title" : "Teacher App",
                "description" : "Student Information Management manages student data, schedules, and generates analytics for administrators to make data-driven decisions."
            },
            {
                "id" : 2,
                "image" : "https://cdn.pixabay.com/photo/2017/06/14/16/20/network-2402637__340.jpg",
                "title" : "Management App",
                "description" : "The ERP module manages tasks, projects, documents, user access, and security, enabling managers to assign tasks, track progress, allocate resources, and ensure appropriate user access based on roles."
            },
            {
                "id" : 3,
                "image" : "https://cdn.pixabay.com/photo/2017/07/16/07/50/invitation-2508607_960_720.jpg",
                "title" : "Visitor App",
                "description" : "Visitor registration captures visitor details, including name, contact information, purpose, and arrival time. Check-in/check-out allows accurate records, while the app ensures security and compliance by tracking visitor data."
            },
            {
                "id" : 4,
                "image" : "https://cdn.pixabay.com/photo/2017/07/16/07/50/invitation-2508607_960_720.jpg",
                "title" : "Parent App",
                "description" : "Parents track child's academics, attendance, and teacher interaction. ERP module handles fees and communication among teachers, students, and parents."
            },
            {
                "id" : 5,
                "image" : "https://cdn.pixabay.com/photo/2017/07/16/07/50/invitation-2508607_960_720.jpg",
                "title" : "Principal App",
                "description" : "The core module, also known as the principal app module, is the foundation for the entire system, managing business processes and providing a unified platform for enterprise operations."
            }
        ]
    },
    {
        "id" : 3,
        "title" : "Smart Technology",
        "services" : [
            {
                "id" : 1,
                "image" :PaymentGateway,
                "title" : "Payment  Gateway",
                "description" : "Our ERP software integrates multiple payment gateways, enabling businesses to accept payments from various sources and streamlining accounts receivable processes through automated reminders"
            },  {
                "id" : 2,
                "image" : Barcode,
                "title" : "Barcode",
                "description" : "Our software integrates a barcode with ERP inventory management, enabling efficient stock tracking, product identification, and order processing. It also collects data for insights into sales patterns and performance metrics."
            },  {
                "id" : 3,
                "image" : BusinessWhatsApp,
                "title" : "Business  WhatsApp",
                "description" : "ERP integrates education with WhatsApp for efficient admin communication, enhancing institutions' operational effectiveness."
            },  {
                "id" : 4,
                "image" : RFID,
                "title" : "RFID",
                "description" : "RFID technology integrates in ERP software to track and manage educational institutions, using radio frequency signals to identify objects and individuals with RFID tags or cards."
            },
            {
            "id" : 5,
            "image" : TextSMS,
            "title" : "Text SMS",
            "description" : "Our ERP software integrates SMS module for real-time communication, improved workflow efficiency, and alerts to various stakeholders, enhancing communication and efficiency."
        },
        {
        "id" : 6,
        "image" : Punch,
        "title" : "Punch In/Out",
        "description" : "Our ERP software features time tracking for employee attendance, integrating punch in/out modules with HR and payroll components to accurately manage work hours and attendance."
    }
        ]
    },
    {
        "id" : 4,
        "title" : "E-Learning",
        "services" : [
            {
                "id" : 1,
                "image" : "https://cdn.pixabay.com/photo/2015/05/31/10/55/man-791049_960_720.jpg",
                "title" : "Assignment-",
                "description" : "The assignment module streamlines assignment submission, grading, and assessment for educators, ensuring student progress and coursework organization."
            },{
                "id" : 2,
                "image" : "https://cdn.pixabay.com/photo/2016/06/03/13/57/digital-marketing-1433427_960_720.jpg     ",
                "title" : "Event/Gallery",
                "description" : "An event/gallery module manages and displays events in an ERP system, allowing users to schedule, schedule, and display details."
            },{
                "id" : 3,
                "image" : "https://cdn.pixabay.com/photo/2015/07/17/22/43/student-849825_960_720.jpg",
                "title" : "Notice Board",
                "description" : "The notice board module in ERP software functions as a digital bulletin board, centralized communication platform, and information dissemination tool."
            },{
                "id" : 4,
                "image" : "https://cdn.pixabay.com/photo/2018/04/30/08/10/business-3362086_960_720.jpg",
                "title" : "Live Class",
                "description" : "ERP software's live class module manages interactive classes, ideal for educational institutions offering online learning and instructor-led sessions."
            },
            {
                "id" : 5,
                "image" : "https://cdn.pixabay.com/photo/2018/04/30/08/10/business-3362086_960_720.jpg",
                "title" : "Report Card",
                "description" : "Report card module streamlines student report card creation, management, sharing, improving communication, transparency, and promoting academic excellence."
            }
        ]
    },
    {
        "id" : 5,
        "title" : "Website",
        "services" : [
            {
                "id" : 1,
                "image" : "https://cdn.pixabay.com/photo/2017/03/13/17/26/ecommerce-2140604_960_720.jpg",
                "title" : "Web designing",
                "description" : "A payment gateway is a technology used by merchants to accept debit or credit card purchases from customer"
            },
            {
                "id" : 2,
                "image" : "https://cdn.pixabay.com/photo/2019/02/02/14/27/qr-code-3970681_960_720.jpg",
                "title" : "Image Gallery",
                "description" : "TEC-IT Barcode Software you generate barcodes as part of applications or web-sites"
            }
            ,
            {
                "id" : 3,
                "image" : "https://cdn.pixabay.com/photo/2017/07/14/09/28/whatsapp-2503235_960_720.jpg",
                "title" : "CMS",
                "description" : "WhatsApp Business from Meta WhatsApp Business enables you to have a business presence on WhatsApp,"
            },
            {
                "id" : 4,
                "image" : "https://cdn.pixabay.com/photo/2020/01/11/18/13/alexa-4758340_960_720.jpg",
                "title" : "Web designing",
                "description" : "Alexa is Amazon's cloud-based voice service available on hundreds of millions of devices from "
            },
            
        ]
    }
]


export const home_Aboutus=
    {
        title:"ABOUT US",
        description:"Unlock seamless integration, real-time data insights, and optimized workflows with our customizable ERP software tailored to your unique business needs.",
    }



export const why_generic_title=
    {
        title:"WHY GENERIC",
        description:"Generic ERP Software serves as a comprehensive solution to empower educational institutions to thrive in the digital age. Experience the benefits of a user-friendly, efficient, and versatile software solution.",
    }



export const why_generic_content=[
    {
        "id" : 1,
        "title": "User Friendly Software",
        "description":"Our user friendly interface is designed to provide a seamless and intuitive experience for all users, regardless of their technical expertise. We are dedicated to delivering a user-friendly interface that empowers your team, fosters productivity, and  maximizes the value of our software for your business. Experience the ease and efficiency of our user interface by contacting us today. Let's simplify your work processes together.",
        "image":user
    
    },
    {
        id : 2,
        title: "Data Security and Backup",
        description:"We understand the paramount importance of data security for your business.We take data protection seriously and have implemented robust measures to ensure the confidentiality, integrity, and availability of your valuable information. While we implement advanced security measures, we also encourage our clients to follow best practices in user access management, password policies, and general data protection. ",
        image:security
    },
    {
        "id" : 3,
        "title": "Support",
        "description":"We believe that providing excellent client support is as crucial as delivering top-notch software solutions. Our dedication to your success extends beyond the implementation phase, as we strive to be your trusted partner on your business journey. Our commitment to excellent client support is unwavering. When you choose us as your software partner, you can rest assured that you will receive the care, attention, and expertise you need to achieve your goals.",
        "image":Support
    },
    {
        "id" : 4,
        "title": "Anytime and Anywhere Accessibility",
        "description":"We understand the importance of staying connected to your business, no matter where you are. That's why our software offers anytime, anywhere accessibility, providing you with the flexibility and convenience to manage your operations on the go. We believe that empowering your business means giving you the freedom to work whenever and wherever you choose. Experience the convenience and flexibility of anytime, anywhere accessibility by choosing our software.",
        "image": Anytime
    },
    {
        "id" : 5,
        "title": "Easy Data Migration",
        "description":"We are aware that switching to a new software solution might raise serious concerns about data transfer. We have created a simplified procedure that makes data migration simple and effective in order to guarantee a hassle-free and smooth migration experience. You may confidently migrate to our software thanks to our simple data transfer method and do so without experiencing any disruptions or data loss. Let us handle your data migration so you can concentrate on advancing your company with our potent solution.",
        "image":Migration
    },
    {
        "id" : 6,
        "title": "Easy and Faster Communication",
        "description":"We recognise how crucial timely and effective communication is to the success of your company. Because of this, our software is made to offer quick and simple channels for communication, enabling your team to work smoothly in real-time. Your team can work more productively, interact effectively, and make informed choices quickly with the availability of quick and easy communication. Utilise our tools to discover the potential of real-time communication.",
        "image":Transfer
    },
    {
        "id" : 7,
        "title": "Paperless Work & Better Reporting",
        "description":"We are passionate about assisting companies in making the switch to a paperless workplace, enabling them to increase productivity and simplify their reporting procedures. With the help of our software, you can easily produce meaningful reports and become paperless. The effectiveness, productivity, and decision-making abilities of your organisation may be significantly increased by becoming paperless and introducing improved reporting procedures. Embrace the digital transition to reap the rewards of our software.",
        "image":Paper
    }
]
// export const home_testimonials=[
    
//         "testimonials": [
//             {
//                 "image": testimonial1,
//                 "title": "New Oxford Public School",
//                 "description": "You are better so far for me gentlemen, You are always ready to answer my questions and intervene when necessary. You did a good job, keep it up."
//             },
//             {
//                 "image": testimonial2,
//                 "title": "Yuvashakti Public School",
//                 "description": "Very good support and profesional. i like work you guys continued. very good app management.thank you very much !!!"
//             },
//             {
//                 "image": testimonial3,
//                 "title": "Tulips International School",
//                 "description": "Well organised and commented code, easy to follow through ad make just about any customisation you need. Good stuff!"
//             },
//             {
//                 "image": testimonial4,
//                 "title": "Brilliants Convent School",
//                 "description": "Excellent team with excellent product and with excellent support. The best ERP solutions for school."
//             }
// ]
