import './LandingSection.css'



const LandingSection = ({Background, Title, Description}) => {
  return (
    <div className='LandingSection-Main-Container' style={{backgroundImage: `url(${Background})`}}>
        <div className="LandingSection-Details-Container">
          <div
            style={{
              width: "60%",
              gap: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="LandingSection-title">{Title}</div>
            <div className="LandingSection-description">{Description}</div>
          </div>
        </div>
    </div>
  )
}
export default LandingSection