
import "./erpSectionTwo.css";
// import content from "../../../Model/whygeneric.content.json";
import { useState } from "react";
// import { TabMenu } from "primereact/tabmenu";

const ErpSectionTwo = (image, description) => {
  // const [Contents, setContents] = useState(content[0]);
  const [currentId, setCurrentId] = useState(1);

  const handleButtonClick = (index) => {
    setCurrentId(index);
  };

  const CustomButton = ({ title, index, isActive, handleClick  }) => {

    return (
      <div
        className={`Custom_Button ${isActive ? 'active' : ''}`}
        onClick={() => {
          handleClick(index);
        }}
      >
        {title}
      </div>
    );
  };


  const item1 = [
    {
      id: 1,
      title: "ADMISSION MANAGEMENT",
      properties: {
        image:"https://img.freepik.com/free-photo/successful-schoolgirl_1098-13593.jpg?t=st=1684932076~exp=1684932676~hmac=d680932286f4e9cceca2eb464013c250e0e48874bf8ef2380abfb84c88763f43",
        description: "Welcome to our school admission process, where we aim to provide a seamless and transparent experience for prospective students and their families. Our admission process is designed to be inclusive, fair, and efficient. We value diversity and believe in providing equal opportunities for all students. Our dedicated admissions team is here to guide you through every step, ensuring a smooth application process and addressing any queries you may have. We prioritize the well-being and academic growth of our students, offering a nurturing and supportive environment. Join our educational community and embark on a journey of discovery, growth, and excellence. Apply today and take the first step towards a bright future."
      },
    },
    {
      id: 2,
      title: "STAFF RECRUITMENT",
      properties: {
        description:
          "At our organization, we prioritize finding the right talent to join our team. Our staff recruitment process is comprehensive and meticulous, ensuring we select individuals who align with our values, possess the necessary skills, and contribute to our organizational goals. We employ various channels, including job postings, networking, and partnerships with educational institutions, to attract a diverse pool of candidates. Our recruitment team conducts thorough screenings, interviews, and assessments to assess candidates' qualifications, experience, and cultural fit. We strive to provide a positive and inclusive recruitment experience, valuing transparency and open communication. Join our team and become part of a dynamic and collaborative work environment that fosters growth and career development",
      image:"https://img.freepik.com/free-photo/smiling-business-lady-working-with-colleagues_1262-2153.jpg?t=st=1684932239~exp=1684932839~hmac=76b3b15760d3c28479764378ffcf174a903a74dfa08a8cc3932ac691e557cac1"
        },
    },
    {
      id: 3,
      title: "LIBRARY MANAGEMENT",
      properties: {
        image:"https://img.freepik.com/free-photo/abstract-blur-defocused-bookshelf-library_1203-9640.jpg?t=st=1684932318~exp=1684932918~hmac=3a7690f3d60ce56c0d4a0d85420a4c332283edb1a7927646d3b37fa4cf7ea63e",
        description:
          "Library management is a multifaceted process that involves organizing and maintaining a collection of resources to meet the information needs of patrons. It encompasses various tasks, including cataloging, classification, circulation, and inventory management. Effective library management also involves implementing technology solutions for efficient operations, such as automated systems for checkouts and returns. Additionally, library managers play a crucial role in developing programs and services that promote literacy, learning, and community engagement. They collaborate with staff, oversee budgeting, and stay updated on emerging trends in information management. A well-managed library serves as a hub of knowledge, fostering a love for reading and lifelong learning among its users",
      },
    },
    // {
    //   id: 4,
    //   title: "REQUEST",
    //   properties: {
    //     image:"https://www.pngmart.com/files/7/Support-PNG-Transparent-Picture.png",
    //     description:
    //       " There have been many requests for the product. a written statement of petition: If you need supplies, send in a request.",
    //   },
    // },
    {
      id: 4,
      title: "COMMUNICATION",
      properties: {
        image:"https://www.pngmart.com/files/7/Support-PNG-Transparent-Picture.png",
        description:
          "Communication is the lifeblood of human interaction. It is the foundation upon which relationships, collaboration, and understanding are built. Effective communication involves not only expressing thoughts and ideas clearly but also active listening and empathy. It encompasses verbal and non-verbal cues, fostering connections and resolving conflicts. In today's digital age, communication has expanded to include various channels like emails, social media, and video calls. Effective communicators adapt their approach based on the audience and context, utilizing both written and spoken words to convey their message. Strong communication skills are essential in personal, professional, and societal realms, enabling mutual understanding and harmony",
      },
    },
    {
      id: 5,
      title: "EMPLOYEE INFORMATION",
      properties: {
        image:"https://img.freepik.com/free-photo/businessman-using-application-contact-with-his-colleagues_1134-644.jpg?t=st=1684932547~exp=1684933147~hmac=479490078c934d88bbc5f385a341959323da4dba88e170fdb229ae258b93fa3a",
        description:
          "Our employee information management system offers a centralized and secure platform for storing and accessing vital employee data. From personal details and contact information to employment history and performance records, our system ensures accurate and up-to-date information. It simplifies HR processes such as onboarding, leave management, and performance evaluations, improving efficiency and reducing paperwork. With our system, HR professionals can easily generate reports, analyze workforce data, and make informed decisions. Moreover, employees have self-service access to update their information, view payslips, and request time off. Experience streamlined HR operations, enhanced data accuracy, and improved employee engagement with our comprehensive employee information management solution..",
      },
    },
    {
      id: 6,
      title: "EMPLOYEE ATTENDANCE",
      properties: {
        image:"https://img.freepik.com/free-photo/medium-shot-colleagues-discussing-work_23-2149622839.jpg?t=st=1684932641~exp=1684933241~hmac=86571487d4dc2b36a863c241cd1532df55128d3b023c19969d62e38f896f06cd",
        description:
          "Employee attendance is a crucial aspect of workforce management. It refers to the record of employees' presence or absence during scheduled work hours. Monitoring and managing attendance ensure productivity, punctuality, and accountability. Organizations use various methods such as time clocks, biometric systems, or digital attendance tracking tools to accurately capture attendance data. Tracking attendance enables the identification of patterns, such as frequent absences or tardiness, which can then be addressed through appropriate measures. Effective attendance management involves clear attendance policies, regular communication, and incentives for good attendance. By promoting and monitoring employee attendance, organizations can optimize workforce planning, maintain operational efficiency, and foster a culture of reliability and commitment.",
      },
    },
    // {
    //   id: 8,
    //   title: "FEE MANAGEMENT",
    //   properties: {
    //     image:"https://www.processmaker.com/wp-content/uploads/2020/03/681-1-768x431.jpg",
    //     description:
    //       "Fee management is a crucial aspect of financial administration in educational institutions and other organizations. It involves the systematic collection, tracking, and management of fees and payments. Efficient fee management systems automate the process, ensuring accuracy, transparency, and timely reminders for payment deadlines. It includes tasks such as fee structuring, generating invoices, recording transactions, and issuing receipts. Effective fee management also involves addressing queries, providing payment options, and managing overdue payments and late fees. By implementing robust fee management practices, organizations can streamline financial operations, maintain financial stability, and enhance the overall experience for students, parents, and stakeholders.",
    //   },
    // },
    {
      id: 7,
      title: "LESSON PLANNING",
      properties: {
        image:"https://img.freepik.com/free-photo/man-studying-grammar-with-his-colleagues_52683-107810.jpg?t=st=1684932762~exp=1684933362~hmac=84c75f0e104b3bd96608bb87286bc5fb45a6601367b721fd0b4acfe5b305db9d",
        description:
          "Lesson planning is a fundamental aspect of effective teaching. It involves carefully designing instructional strategies, activities, and resources to meet specific learning objectives. A well-structured lesson plan outlines the sequence of content, learning outcomes, assessment methods, and differentiation strategies. It considers the needs and abilities of students, catering to diverse learning styles. Lesson planning also includes incorporating technology, engaging materials, and interactive teaching methods. It allows educators to create a cohesive and engaging learning experience, ensuring a clear progression of knowledge and skills. By investing time in thoughtful lesson planning, teachers can maximize student engagement, optimize learning outcomes, and create a positive classroom environment conducive to academic growth.",
      },
    },
    {
      id: 8,
      title: "MOBILE SMS",
      properties: {
        image:"https://img.freepik.com/free-photo/phone-computer-graphic-showing-mobile-phone-lifestyle_1134-771.jpg?t=st=1684932869~exp=1684933469~hmac=ea4507bebab2070d4f0ab3254d48d641d523ed53f5e7ff990ceae3ba32f975f6",
        description:
          "Mobile SMS, or Short Message Service, is a widely used communication tool that allows the exchange of text messages between mobile devices. With its simplicity and convenience, SMS has become an integral part of personal and business communication. It enables instant and direct communication, regardless of location, providing a quick and efficient way to convey information, updates, and notifications. SMS marketing campaigns leverage this medium to reach a wide audience, promoting products, services, and events. Additionally, SMS authentication adds an extra layer of security by verifying user identities. Despite the rise of messaging apps, SMS remains a reliable and accessible means of communication in today's mobile-driven world.",
      },
    },
    {
      id: 9,
      title: "PAYROLL MANAGEMENT",
      properties: {
        image:"https://img.freepik.com/free-photo/account-assets-audit-bank-bookkeeping-finance-concept_53876-133899.jpg?t=st=1684932918~exp=1684933518~hmac=7a2dd8005755e4c2abeedbb3a34d826b1892fffe412acb1c8b8dc83b1e46d31e",
        description:
          "Payroll management is a critical function of every organization. It involves the efficient administration of employee compensation, including wages, salaries, deductions, and taxes. Payroll management ensures accurate and timely payment processing, compliance with legal requirements, and maintenance of payroll records. It encompasses tasks such as calculating hours worked, managing leave and attendance, and implementing payroll policies. Efficient payroll systems automate processes, reducing manual errors and saving time. Effective payroll management also involves staying updated on tax regulations, issuing pay stubs, and addressing employee inquiries. By streamlining payroll operations, organizations can ensure employee satisfaction, financial accuracy, and legal compliance, ultimately contributing to overall organizational success.",
      },
    },
    {
      id: 10,
      title: "DAILY REMARKS",
      properties: {
        image:"https://img.freepik.com/free-photo/top-view-online-psychologist-concept_23-2149407925.jpg?t=st=1684932987~exp=1684933587~hmac=92eef4415400159ffb6346ae6166d3863e1a8fbf7f4b31343608b75d3c07a99b",
        description:
          "Daily remarks are brief notes or comments made by individuals to capture observations, reflections, or important information on a daily basis. These remarks serve as a record of significant events, progress, or insights throughout the day. Whether used in personal or professional contexts, daily remarks provide a snapshot of one's experiences and thoughts. They can be used to track personal goals, document project updates, or communicate important details with colleagues or supervisors. Daily remarks help in organizing thoughts, improving self-awareness, and facilitating effective communication. By capturing these daily remarks, individuals can gain valuable insights, foster growth, and enhance productivity",
      },
    },
    {
      id: 11,
      title: "TRANSPORT MANAGEMENT",
      properties: {
        image:"https://img.freepik.com/free-photo/man-by-truck-guy-delivery-uniform-man-with-clipboard_1157-46192.jpg?t=st=1684933046~exp=1684933646~hmac=84e7333c3d703bec8c9a7f2539a1f7c1ae489ce9fb2c51fe738bf833fe041d48",
        description:
          "Transport management plays a vital role in ensuring the efficient movement of goods, people, and resources. It involves planning, organizing, and overseeing various transportation activities. Effective transport management includes tasks such as route optimization, fleet management, scheduling, and monitoring vehicle maintenance. It also encompasses ensuring compliance with transportation regulations, managing logistics, and implementing safety measures. With the advancements in technology, transport management systems provide real-time tracking, automated processes, and data analysis for improved decision-making. Well-managed transport systems enhance operational efficiency, reduce costs, and minimize delays. By effectively managing transportation, organizations can meet customer demands, enhance supply chain management, and contribute to sustainable and reliable transportation networks.",
      },
    },
    
  ];


  return (
    <div className="erpsectionTwo-main">
      <div className="erpsectionTwo_title">
        <div className="erpsectionTwo-Text">ERP MODULES</div>
        </div>
      <div className="erpsectiontwo-subtitle">
        <div className="erpsectiontwo-subtitle-content">
          {/* <TabMenu model={items} /> */}
          {item1.map((item, index) => {
           return <CustomButton 
           key={index}
           title={item.title} 
           index={item.id} 
           isActive={currentId === item.id}
           handleClick={handleButtonClick} />;
          })}
        </div>
      </div>
      <div className="erpsectionTwoContant-main">
        <div className="erpseciontwoContant-section-left">
          <div className="erpsectiontwoContent-image">
            <img src={item1[currentId-1]?.properties.image}alt="" />
          </div>
        </div>
        <div className="erpseciontwoContant-section-right">
          <div className="erpsectiontwoContent-text">
            {item1[currentId-1]?.properties.description}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErpSectionTwo;
