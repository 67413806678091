import "./RfidTwo.css";
// import content from "../../../Model/whygeneric.content.json";
import { useState } from "react";
// import { TabMenu } from "primereact/tabmenu";

const Rfidtwo = (image, description) => {
  // const [Contents, setContents] = useState(content[0]);
  const [currentId, setCurrentId] = useState(1);

  const handleButtonClick = (index) => {
    setCurrentId(index);
  };

  const CustomButton = ({ title, index, isActive, handleClick  }) => {

    return (
      <div
        className={`Custom_Button ${isActive ? 'active' : ''}`}
        onClick={() => {
          handleClick(index);
        }}
      >
        {title}
      </div>
    );
  };

  const item1 = [
    {
      id: 1,
      title: "Reduced Manpower Need",
      properties: {
        image:"https://images.pexels.com/photos/3830745/pexels-photo-3830745.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        description: "With this approach, the hardware may be operated without the involvement of humans. The recorded attendance is instantly recorded. By sending the majority of SMS in a matter of seconds, it lessens staff burdens."
      },
    },
    {
      id: 2,
      title: "User Friendly",
      properties: {
        description:
          "An easy-to-use biometric student attendance system for schools/colleges is EduAppz RFID system. It is one of the best online systems for tracking students' attendance in classrooms. Additionally, the online login is equipped with detailed MIS data and graphical dashboards.",
      image:"https://img.freepik.com/free-photo/finger-touching-phone-with-wifi_1134-224.jpg?w=996&t=st=1691323255~exp=1691323855~hmac=5aa85e89f12c36a908665c023395efc7a5270145c35dba3782200642cc7aa913"
        },
    },
    {id: 3,
      title: "Cost-Effective Approach",
      properties: {
        image:"https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041856.jpg?w=1060&t=st=1691323341~exp=1691323941~hmac=b5f34bc5b5813c1937a02dcd1a945f1ae6e85634ad03a4da7545119636e84bdd",
        description:
          "EduAppz Technologies guarantees a cost-effective solution with the best outcomes when it comes to the pricing of an RFID  attendance system. In comparison to a letter or phone call, this approach is more affordable and efficient.",
      },
    },
    // {
    //   id: 4,
    //   title: "REQUEST",
    //   properties: {
    //     image:"https://www.pngmart.com/files/7/Support-PNG-Transparent-Picture.png",
    //     description:
    //       " There have been many requests for the product. a written statement of petition: If you need supplies, send in a request.",
    //   },
    // },
    {
      id: 4,
      title: "Solution for Streamlining Attendance",
      properties: {
        image:"https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041850.jpg?w=996&t=st=1691323436~exp=1691324036~hmac=df23a115b12aae73c3afa236f3896979f1658d35fd7b95cc167b10f771777c17",
        description:
          "Without a doubt, this clever method makes it easier to increase student attendance. It offers the choice of customizable schedules for Day In and Day Out message transmission. It aids the administration of the school/college in keeping an eye on student attendance, which in turn aids in maintaining school/college discipline and earning reputation.",
      },
    },
    {
      id: 5,
      title: "Entirely Safe and Secure Monitoring",
      properties: {
        image:"https://img.freepik.com/free-photo/business-colleagues-discussing-together-office-night-teamwork-partnership_482257-32811.jpg?w=1060&t=st=1691323523~exp=1691324123~hmac=0e002efad25d349441dae17889fcf13a21e8ecb53934c6170aefd7726e9d8182",
        description:
          "The use of SMS in a student attendance tracking system demonstrates its high level of kid safety. Parents are properly informed of when their children arrive at and leave from school/college. Authorised school/college officials can keep an eye on the children and manage student attendance more effectively.",
      },
    },
    {
      id: 6,
      title: "Improved interaction with parents",
      properties: {
        image:"https://img.freepik.com/free-photo/mother-daughter-spending-time-together-living-room-mother-teaching-her-daughter-homeschooling-online-via-laptop-remote-study-elearning_640221-509.jpg?w=996&t=st=1691323594~exp=1691324194~hmac=5bcbcaf53e869fea1cd0646aa1c590dd275fe42a5446e03ab8e70017c99cb836",
        description:
          "By providing all the necessary information to the parents about their ward promptly and efficiently, RFID performs a very important function. decreasing the likelihood of unforeseen occurrences and communication gaps.",
      },
    },
    // {
    //   id: 8,
    //   title: "FEE MANAGEMENT",
    //   properties: {
    //     image:"https://www.processmaker.com/wp-content/uploads/2020/03/681-1-768x431.jpg",
    //     description:
    //       "Fee management is a crucial aspect of financial administration in educational institutions and other organizations. It involves the systematic collection, tracking, and management of fees and payments. Efficient fee management systems automate the process, ensuring accuracy, transparency, and timely reminders for payment deadlines. It includes tasks such as fee structuring, generating invoices, recording transactions, and issuing receipts. Effective fee management also involves addressing queries, providing payment options, and managing overdue payments and late fees. By implementing robust fee management practices, organizations can streamline financial operations, maintain financial stability, and enhance the overall experience for students, parents, and stakeholders.",
    //   },
    // },
    {
      id: 7,
      title: "Other Prominent Domains",
      properties: {
        image:"https://img.freepik.com/free-photo/paying-bills-by-scanning-qr-code-is-faster-easier_329181-4296.jpg?w=996&t=st=1691323830~exp=1691324430~hmac=bcd812560abf9deec2d80a3c6f38fe4fefe220937a5dd926d13e18edd27fda9b",
        description:
          "Logistics and warehouse management, inventory and stock management. Additionally useful for rapid communication (e.g., Sports Day Cancelled, Parent Reminders, Excursion Late Bus, etc.) and emergency broadcasting (e.g., Floods, Bushfires, etc.).",
      },
    },
  
   
    
  ];


  return (
    <div className="rfidTwo-main">
      <div className="rfidTwo_title">
        <div className="rfidTwo-Text">Benefits of RFID for School/College Attendance</div>
        </div>
      <div className="rfidtwo-subtitle">
        <div className="rfidtwo-subtitle-content">
          {/* <TabMenu model={items} /> */}
          {item1.map((item, index) => {
           return <CustomButton 
           key={index}
           title={item.title} 
           index={item.id} 
           isActive={currentId === item.id}
           handleClick={handleButtonClick} />;
          })}
        </div>
      </div>
      <div className="rfidTwoContant-main">
        <div className="rfidtwoContant-section-left">
          <div className="rfidtwoContent-image">
            <img src={item1[currentId-1]?.properties.image}alt="" />
          </div>
        </div>
        <div className="rfidtwoContant-section-right">
          <div className="rfidtwoContent-text">
            {item1[currentId-1]?.properties.description}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Rfidtwo;
