import './VideoBox.css'
import School from '../../Assets/landingPage/schoolManagement.png'
import College from '../../Assets/landingPage/CollegeManagement.png'
import Factory from '../../Assets/landingPage/FactoryManagement.png'
import logo from "../../Assets/image/logo (2).png"
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
const VideoBox = () => {
    const navigate = useNavigate();
    return (
        <div className='VideoBox-main-container'>
            <div className='VideoBox-overlay'>
                <div>
                    <div className='VideoBox-sub-title'>
                        {/* <img src={logo} alt="" /> */}
                    </div>
                    <div className='VideoBox-title'>The Ultimate Factory & <br />Educational Management Software</div>
                </div>
                <div className='VideoBox-description'>Run your institution more efficiently by digitizing and automating daily tasks and improving
                    parental involvement through better parent-teacher communication</div>
                <div className='VideoBox-button-container'>
                    <a style={{textDecoration:"none"}} href="" download="https://drive.google.com/file/d/1ENuADT5g0jLcb7AYAqwLAbB6Hl8dujLz/view?usp=sharing">
                        <button className='VideoBox-button'>Download E Brochure</button>
                    </a>
                    <div className='VideoBox-button' onClick={()=>{
             navigate(siteMap.ContactUsPage.path, { replace: true });
        }}>Ask for Demo</div>
                </div>
                <div className='VideoBox-button-card-container'>
                    <div className='VideoBox-button-card'>
                        <img className='VideoBox-button-card-image' src={School} alt='school' />
                        School Management Software
                    </div>

                    <div className='VideoBox-button-card'><img className='VideoBox-button-card-image' src={Factory} alt='school' />Factory Management
                        Software</div>
                    <div className='VideoBox-button-card'><img className='VideoBox-button-card-image' src={College} alt='school' />College Management
                        Software</div>
                </div>
            </div>

            <div className="VideoBox-filter" />
            <video
                className="VideoBox-element"
                src='https://dashdrop-setup.s3.ap-south-1.amazonaws.com/pexels-ivan-samkov-7252683+(1080p).mp4'
                muted
                loop
                autoPlay
            />
        </div>
    );
}

export default VideoBox