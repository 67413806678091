import "./Footer.css";
import logo from "../../Assets/image/logosmall.svg"
import { Divider } from 'primereact/divider';
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
const Footer = () => {
  const navigate = useNavigate();

const start = Date.now();
// Your operation
const totalTimeTaken = Date.now() - start;
console.log(totalTimeTaken);
const mobile_res = window.screen.width <= 720



  return (
    <footer className="footer-main-container">
      <div className="footer-container-1">
        {/* <div className="footer-container-1-title">
          Get connected with us on social media
        </div>
        <div className="footer-container-1-social-container">
        <div
        className="item"
            onClick={() => {
              window.open("https://twitter.com/ZedByte");
            }}
          >
            <TwitterIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <div
          className="item"
            onClick={() => {
              window.open("https://www.instagram.com/zedbytecorp/");
            }}
          >
            <InstagramIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <div
          className="item"
            onClick={() => {
              window.open("https://wa.me/+918825464712");
            }}
          >
            <WhatsAppIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <div
          className="item"
            onClick={() => {
              window.open("tel:+918825464712");
            }}
          >
            <CallIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
        </div> */}
      </div>
      <div className="footer-container-2">
        <div className="footer-container-2-left">
          <div className="footer-container-2-left-warp">
            <div className="footer-container-2-left-title">
              <img src={logo} alt="" />
              {/* GENERIC SOFTWARE SOLUTION */}
            </div>
            <div className="footer-container-2-left-content">
              Grow Your Educational Institution With India's Most Trusted
              Educational Management Software 
            </div>
          </div>
          {
            !mobile_res ? <Divider layout="vertical" /> : null
          }
        
        </div>


        <div className="footer-container-2-right">
          <div className="footer-container-2-right-warp">

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Product & Services</div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.SchoolmanagementPage.path, { replace: true });
        }}>
                School Management Software
              </div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.CollegemanagementPage.path, { replace: true });
        }}>
                College Management Software
              </div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.IndutrialmanagementPage.path, { replace: true });
        }}>
                Industry Management Software
              </div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.MobileappServicePAge.path, { replace: true });
        }}>
                Mobile Apps
              </div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.ElearningPage.path, { replace: true });
        }}>
                E-Learning Software
              </div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.WebsitePage.path, { replace: true });
        }}>Website Design</div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.RfidPage.path, { replace: true });
        }}>Biometric/RFID System</div>
            </div>

            {/* {
            !mobile_res ? <Divider layout="vertical" /> : null
          } */}

            {/* <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Let's Connect</div>
              <div className="footer-container-2-right-content">Careers</div>
              <div className="footer-container-2-right-content">Reach Us</div>
            </div> */}

            {
            !mobile_res ? <Divider layout="vertical" /> : null
          }

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Blog</div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.BlogContentPage.path,{state:{id:1}}, { replace: true });
        }}>School ERP software</div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.BlogContentPage.path,{state:{id:2}}, { replace: true });
        }}>Student Engagement</div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.BlogContentPage.path,{state:{id:3}}, { replace: true });
        }}>School Management System</div>
              <div className="footer-container-2-right-content" onClick={()=>{
            navigate(siteMap.BlogContentPage.path, {state:{id:4}}, { replace: true });

      }}>
              Digital Adoption in Schools
              </div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.BlogContentPage.path, {state:{id:5}},{ replace: true });
        }}>Smart education</div>
            
            <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.Blo.path,{state:{id:6}}, { replace: true });
        }}>Modern Educationt</div>
</div>
            {
            !mobile_res ? <Divider layout="vertical" /> : null
          }

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title" >Know Us</div>
              <div className="footer-container-2-right-content" onClick={()=>{
             navigate(siteMap.AboutPage.path, { replace: true });
        }}>
                About GSSPL
              </div>
              {/* <div className="footer-container-2-right-content">
                Implementation Strength              
              </div>
              <div className="footer-container-2-right-content">
                Teechnnical Strength
              </div> */}
            </div>
           

          </div>
        </div>
      </div>
      <div className="footer-container-3">
        <h1 className="footer-container-3-content">
          Designed and developed by <span style={{cursor:"pointer"}} onClick={()=>{window.location.replace("https://genericsoftware.in/")}}>GENERIC SOFTWARE SOLUTIONS PVT.LTD</span>
        </h1>
      </div>
    </footer>
  );
};
export default Footer;
