import "./Rfid.css";
import SectionOne from "../../../Components/Sectionone/Sectionone";
// import logo from "../../../Assets/landingPage/carousel-2.png";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import { motion } from 'framer-motion'
import {services_heroSection_Content} from "../../../Model/HeroSection.content"
import LandingSection from "../../../Components/LandingSection/LandingSection";
import RfidTwo from "../Rfid/RfidTwo/RfidTwo";
import { useEffect } from 'react';
import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";


const Rfid =()=>{
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
    const RfidContant = services_heroSection_Content.Rfid

      const RFidCardContant=
      [
        {
        image: "https://img.freepik.com/free-vector/mobile-ui-ux-concept-illustration_114360-11697.jpg?t=st=1684938070~exp=1684938670~hmac=efea9b2d29cc3a9c089ee88ab172f8d36a240d27d72d59b8bda3f323012504cb",
        cardtitle:"UI/Ux solutions",
        carddescription:"Our UI/UX designs prioritize user-centric experiences, delivering intuitive interfaces that enhance usability and satisfaction."
      },{
        image: "https://img.freepik.com/free-vector/data-inform-illustration-concept_114360-864.jpg?t=st=1684937915~exp=1684938515~hmac=57c6a19756848e96623946e4506ffc63631c362f6730fdab660581841af8f75c",
        cardtitle:"Web Development",
        carddescription:"We specialize in web development, crafting websites that are visually appealing,  optimized for optimal performance."
      },
      {
        image: "https://img.freepik.com/free-vector/mobile-testing-concept-illustration_114360-1564.jpg?t=st=1684937899~exp=1684938499~hmac=d01220e513b5eb2efed910175b3333532aa8142bf07a5ba409bdd0dd179f9f5b",
        cardtitle:"Application Development",
        carddescription:"We excel in application development, creating innovative and customized solutions that meet your specific business needs"
      },{
        image: "https://img.freepik.com/free-vector/marketing-consulting-concept-illustration_114360-9027.jpg?t=st=1684937819~exp=1684938419~hmac=82cc764fe372132c919e02e383e07684d404db30662a6e73a3da248e988ed5f9",
        cardtitle:"Digital Marketing",
        carddescription:"Our digital marketing strategies drive online success converting customers through targeted campaigns and effective strategies."
      }
    ]
    ;
    return(
        <div className="rfid-main-container">
      <LandingSection Background="https://img.freepik.com/free-photo/smart-watch-contactless-cashless-payment_53876-97186.jpg?t=st=1684925760~exp=1684926360~hmac=5234530e582092094d40c47bd4c60f75efab37747f8450bee201183037af01d6" Title={RfidContant.title} Description={RfidContant.description} />
      <ServiceMenu/>
      {/* <SectionOne
        title={RfidContant.title}
        description={RfidContant.description}
        image={RfidContant.image}
        subtitle={RfidContant.subtitle}
        subimage={RfidContant.subimage}
      /> */}
        <ContentTemplate description={RfidContant.subtitle} image={RfidContant.subimage} isReverse={false} />
        <ContentTemplate description={RfidContant.subtitleone} image={RfidContant.subimageone} isReverse={true} />
        <RfidTwo/>
        {/* <div className="RfidcontentWrapper">{


        RFidCardContant.map((rfid)=>{
return <div className="Rfidcard-container">
     <div className="Rfidcard-box">
      <div className="RfidCard_image">
        
          <motion.img 
          initial={{ scale: 0 }}
          whileInView={{ scale: 1, transition: { duration: 2 } }}
          className="AppMockUp" 
          src={rfid.image} 
          alt="" 
          />
      </div>
      <div className="Rfidcard-title">{rfid.cardtitle}</div>
      <div className="Rfidcard-description">{rfid.carddescription}</div>
     </div>
    </div>
     })
}
</div> */}
      </div>
    );
};
export default Rfid;