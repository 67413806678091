import "./Header.css";
import { Menubar } from "primereact/menubar";
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../Assets/logo.png";
import BLOGS from "../../Model/Blog.content";

const Header = () => {
  const navigate = useNavigate();

  // const item=[{
  //     label:'+91 9876543120',
  //     icon: 'pi pi-fw pi-plus',
  //     item :[
  //         {
  //         label: 'New',

  //         }
  //     ]
  // }
  // ]

  const items = [
    {
      label: "Educational ERP",
      command: () => {
        navigate(siteMap.ErpPage.path, { replace: true });
      },
    },
    {
      label: "Product & Services",

      items: [
        {
          label: "School Management Software",
          command: () => {
            navigate(siteMap.SchoolmanagementPage.path, { replace: true });
          },
        },
        {
          label: "College Management Software",
          command: () => {
            navigate(siteMap.CollegemanagementPage.path, { replace: true });
          },
        },
        {
          label: "Industry Management Software",
          command: () => {
            navigate(siteMap.IndutrialmanagementPage.path, { replace: true });
          },
        },

        {
          label: "Mobile Apps",
          command: () => {
            navigate(siteMap.MobileappServicePAge.path, { replace: true });
          },
        },

        {
          label: "E-Learning Software",
          command: () => {
            navigate(siteMap.ElearningPage.path, { replace: true });
          },
        },

        {
          label: "Website Design",
          command: () => {
            navigate(siteMap.WebsitePage.path, { replace: true });
          },
        },

        {
          label: "Biometric/RFID System",
          command: () => {
            navigate(siteMap.RfidPage.path, { replace: true });
          },
        },
      ],
    },

    {
      label: "Know Us",
      command: () => {
        navigate(siteMap.AboutPage.path, { replace: true });
      },

      // items: [
      //   {
      //     label: "About GSSPL",
      //     command: () => {
      //       navigate(siteMap.AboutPage.path, { replace: true });
      //     },
      //   },
       
      //   {
      //     label: "Implementation Strength",
      //     command: () => {
      //       navigate(siteMap.AboutPage.path, { replace: true });
      //     },
      //   },

      //   {
      //     label: "Teechnnical Strength",
      //     command: () => {
      //       navigate(siteMap.AboutPage.path, { replace: true });
      //     },
      //   },
      // ],
    },
    {
      label: "Clientele",
      command: () => {
        navigate(siteMap.ClientPage.path, { replace: true });
      },
    },
    {
      label: "Contact Us",
      command: () => {
        navigate(siteMap.ContactUsPage.path, { replace: true });
      },
    },
    // {
    //   label: "Lets Connect",
    //   items: [
    //     {
    //       label: "Careers",
    //     },
    //     {
    //       label: "Reach Us",
    //     },
    //   ],
    // },
    {
      label: "Blogs",
  //     items : BLOGS.map((blog) => [
  //       {
  //         label: blog.title,
  //         command: () => {
  //           navigate(siteMap.BlogContentPage.path, {state:{id:blog.id}}, { replace: true });
  //         },
  //       }
  // ])
      items: [
        {
          label: "School ERP software ",
          command: () => {
            navigate(siteMap.BlogContentPage.path, {state:{id:1}}, { replace: true });
          },
        },
        {
          label: "Student Engagement ",
          command: () => {
            navigate(siteMap.BlogContentPage.path, {state:{id:2}} ,{ replace: true });
          },
        },
        {
          label: "School Management System",
          command: () => {
            navigate(siteMap.BlogContentPage.path, {state:{id:3}}, { replace: true });
          },
        },
        {
          label: "Digital Adoption in Schools ",
          command: () => {
            navigate(siteMap.BlogContentPage.path, {state:{id:4}}, { replace: true });
          },
        },
        {
          label: "Smart education",
          command: () => {
            navigate(siteMap.BlogContentPage.path, {state:{id:5}}, { replace: true });
          },
        },
        {
          label: "Modern Education",
          command: () => {
            navigate(siteMap.BlogContentPage.path, {state:{id:6}}, { replace: true });
          },
        },
      ],
    },
    
  ];
  return (
    <div className="Header-main-container">
      {/* top container denotes headroof */}
      <div className="header-top-main-container">
        <div className="headroof-left-container">
          <div className="headroof-title-container">
            <div className="headroof-title"> PARENT HELP</div>
          </div>

          <div className="headroof-contact-container">
            <div className="headroof-contact">
              <WifiCalling3Icon style={{ fill: "#537324", width: "1.2vw" }} />
              <div className="headroof-content">+91 8130567733</div>
            </div>
            <div className="headroof-contact">
              <MailOutlineIcon style={{ fill: "#537324", width: "1.2vw" }} />
              <div className="headroof-content">supoort@genericsoftware.in</div>
            </div>
          </div>
        </div>

        <div className="headroof-social-container">
          <div className="social-follows">
            <div className="socila-follows-us">Follow Us</div>
          </div>

          <div className="social-facebook">
            <FacebookRoundedIcon style={{ fill: "#537324", width: "1.2vw" }} />
          </div>
          <div className="social-instagram">
            <InstagramIcon style={{ fill: "#537324", width: "1.2vw" }} />
          </div>
          <div className="social-instagram">
            <YouTubeIcon style={{ fill: "#537324", width: "1.2vw" }} />
          </div>
          <div className="social-instagram">
            <LinkedInIcon style={{ fill: "#537324", width: "1.2vw" }} />
          </div>
        </div>
      </div>
      {/* navbar starts here */}
      <div className="header-bottom-main-container">
        <div className="logo-container">
          <img style={{cursor:"pointer"}} onClick={()=>navigate(siteMap.HomePage.path, { replace: true })} src={logo} alt="" />
        </div>
        <Menubar model={items} className="Menu-container" />
        <button className="contact-menu-button" onClick={()=>{
             navigate(siteMap.ContactUsPage.path, { replace: true });
        }}>Ask For Demo</button>
      </div>

      {/* <div className="Header-main-root">
        
        <div className="contact-page-contact-container">
                <div className="office-container">
                    <div className="contact-title">Office Address</div>
                   
                </div>
                <div className="office-container">
                   
                    <div className="contact-title">Contact</div>
                   
                </div>
                <div className="Email-container">
                    <i className="fa fa-spinner fa-spin"></i>
                    <div className="contact-title">Email</div>
                    
                </div>
            </div>

        </div> */}
    </div>
  );
};
export default Header;
