import './App.css';
import PageRoutes from "./Routes/Routes";
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { useEffect } from 'react';



function App() {

useEffect(()=>{
  localStorage.setItem("index", 1)
})

  return (
    <div className='app-main-container'>
      <Header/>
      <PageRoutes />
      <Footer />
    </div>
  );
}

export default App;
