import "./Contact.css"
import ContactUs from "../Home/ContactUs/ContactUs";
import ContentTemplate from "../../Components/contentTemplate/ContentTemplate";
import LandingSection from "../../Components/LandingSection/LandingSection";
import {contact_heroSection_Content as ContactUsContant} from "../../Model/HeroSection.content"
import { useEffect } from 'react';

const Contactus = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
  return (
    <div className="Contact-main-container">
      <LandingSection Background={ContactUsContant.image} Title={ContactUsContant.title} Description={ContactUsContant.description} />
      <ContentTemplate description={ContactUsContant.subtitle} image={ContactUsContant.subimage} isReverse={false} />
      <ContactUs />
    </div>
  );

};
export default Contactus;