import "./ContentTemplate.css";
import { useState, useEffect } from "react";
import { motion } from 'framer-motion'

const ContentTemplate = ({ description, image, isReverse }) => {
  const [flexDirection, setflexDirection] = useState("row");
  const mobile_res = window.screen.width <= 720
  const image_attributes = !mobile_res ? {
    initial:{ x: isReverse ? -500 : 500 },
    whileInView:{ x: 0, transition: { duration: 2 } }
  } : {
      initial:{ x: 0 },
  }
  // const [gap, setGap] = useState("0vh");

  useEffect(() => {
    if (isReverse === true) {
      setflexDirection("row-reverse");
    }
    if (window.screen.width <= 720) {
      setflexDirection("column");
      // setGap("3vh");
    }
  }, [isReverse]);

  return (
    <section
      className="section-Wrapper-bottom"
      style={{
        flexDirection: flexDirection,
      }}
    >
      <div className="section-Wrapper-bottom-left">
        <div className="section-Wrapper-bottom-left-content">
          {description}
          {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eligendi
            earum, corrupti maiores doloribus nostrum illum soluta maxime.
            Numquam aliquid suscipit saepe inventore autem quibusdam a ipsum
            dolorum deserunt error eum minima maxime doloribus aut, vero iure
            quos voluptate repudiandae porro soluta reiciendis! Delectus,
            ducimus. Assumenda exercitationem in quia ducimus explicabo? */}
        </div>
      </div>
      {/* <div className="section-Wrapper-bottom-right">
        <div className="section-Wrapper-bottom-right-content">
        <motion.img {...image_attributes} className="content-image" src={image} alt="" />
          
        </div>
      </div> */}
    </section>
  );
};

export default ContentTemplate;
