import "./Clients.css";
import Testimonials from "../../Pages/Home/home_testimonials/home_testimonials";
import ContentTemplate from "../../Components/contentTemplate/ContentTemplate";
import LandingSection from "../../Components/LandingSection/LandingSection";
import{clients_heroSection_Content as ClientContent} from "../../Model/HeroSection.content"
import { useEffect } from 'react';
import testimonial1 from "../../Assets/Testimonials/goodsamarian.svg"
import testimonial2 from "../../Assets/Testimonials/Bhanasthi.svg"
import testimonial3 from "../../Assets/Testimonials/norte.svg"
import testimonial4 from "../../Assets/Testimonials/guru.svg"
import testimonial5 from "../../Assets/Testimonials/joseph.svg"

import testi2 from "../../Assets/Testimonials/testi7.png"
import testi3 from "../../Assets/Testimonials/testi8.png"
import testi4 from "../../Assets/Testimonials/testi9.png"
import testi5 from "../../Assets/Testimonials/testi10.png"
import testi6 from "../../Assets/Testimonials/testi11.png"
// import testi7 from "../../Assets/Testimonials/testi12.png"
import ClientLogo from "../../Components/ClientLogo/Clientlogo";

const Clients =()=>{

    const ContentJson = [
            testimonial1,
            // testimonial2,
            testimonial3,
            testimonial4,
            testimonial5,
        
            testi2,
            // testi3,
            testi4,
            testi5,
            testi6
            // testi7
        ]

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, [])
    return(
        <div className="Clients-main-container">
      <LandingSection Background={ClientContent.image} Title={ClientContent.title} Description={ClientContent.description} />
          <ContentTemplate description={ClientContent.subtitle} image={ClientContent.subimage} isReverse={false} />
          <h1> Our Clients </h1>
         <ClientLogo images={ContentJson}/>
      <Testimonials/>
      </div>
    );
};
export default Clients;