import "./BlogList.css"
import {BlogBox} from "../../../Components/BlogBox/BlogBox"
import SectionOne from "../../../Components/Sectionone/Sectionone";
import Blogs from "../../../Model/Blog.content";

const BlogList = () => {

    const BlogContent = {
        title: "OUR BLOGS",
        description:
            "Discover 15 life-changing habits that will transform your daily routine and boost your productivity",
        image: "https://img.freepik.com/free-photo/team-work-process-photo-young-business-crew-working-with-new-startup-project_1150-9099.jpg?t=st=1685027281~exp=1685027881~hmac=b54af6ec3a04edc034b1c840f51805b968500691e343555de5d5cb8507c9cf61",
        subtitle: "Our ERP client base spans a diverse range of industries, including manufacturing, retail, healthcare, finance, and more. From small businesses to large enterprises, our ERP solutions cater to the unique needs of each client. Our clients rely on our ERP systems to streamline their operations, optimize resource utilization, improve productivity, and enhance decision-making capabilities. By centralizing data, automating processes, and providing real-time insights, our ERP solutions empower our clients to stay ahead of the competition and drive growth. We pride ourselves on building long-lasting partnerships with our clients, delivering customized ERP solutions that address their specific business challenges and contribute to their overall success.",
        subimage: "https://img.freepik.com/free-photo/businessman-businesswoman-shaking-hands-after-making-profita_1163-5263.jpg?t=st=1684931771~exp=1684932371~hmac=8f5e3c21cbf0ee101e53035ff47fc970467a05972c2e973ce9c843ddbb714358"
    };


    return (
        <div className="BlogList-main-container">
            <SectionOne
                title={BlogContent.title}
                description={BlogContent.description}
                image={BlogContent.image}
                subtitle={BlogContent.subtitle}
                subimage={BlogContent.subimage}
            />
            <div className="BlogList-container-Wrapper">
                {
                    Blogs.map((item) => {
                        return <BlogBox id={item.id} image={item.image} title={item.title} description={item.description} date={item.date} />
                    })
                }
            </div>
            {/* <div className="space">

            </div> */}
        </div>
    )
}
export default BlogList