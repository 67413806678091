import "./Service.css"
import { Carousel } from 'primereact/carousel';
import ServiceCard from "../../../Components/ServiceCard/ServiceCard"
import ContainerIntro from "../../../Components/ContainerIntro/ContainerIntro";
import content from "../../../Model/service.content.json"
import { useState } from "react";
import { setServices } from "../../../Redux/action/services.action";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {home_our_service_title,home_our_service} from "../../../Model/Home.content"


const Service = () => {
  const dispatch = useDispatch()
  const GetService = useSelector(state => state.services)
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
    dispatch(setServices(index))
  };


const responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
    }
];
 

  return (
    <div className="Service-main-container">
      <ContainerIntro
        title={home_our_service_title.title}
        subtitle={home_our_service_title.subtitle}
        description={home_our_service_title.description}
      />
      <div className="Service-button-container">
      {/* <div className="magicpattern"></div> */}

        {home_our_service.map((button, index) => (
          <button
            key={index}
            className={activeIndex === index ? "Service-button active" : "Service-button"}
            onClick={() => handleButtonClick(index)}
          >
            {button.title}
          </button>
        ))}

      </div>
      <div className="Service-Card-container">
        <Carousel value={home_our_service[GetService].services} autoplayInterval={3000} numVisible={4} numScroll={3} responsiveOptions={responsiveOptions} itemTemplate={ServiceCard} />
      </div>
    </div>
  )
}
export default Service