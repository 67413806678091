import { useEffect } from 'react'
import './Blog.css'
import { useLocation } from "react-router-dom";
import BLOGS from '../../Model/Blog.content';
import { BlogBox2 } from '../../Components/BlogBox/BlogBox';


const Blog = () => {
    const { state } = useLocation();
    const { id } = state

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        console.log("blog Id=>", id);
    }, [id])

    return (
        <div className='Blog-Content-main-container'>
            <div className='Blog-Upper-container'>
                <div className="Blog-Upper-left">
                    <div className="Blog-Upper-left-title-container">
                        {BLOGS[id - 1].title}
                    </div>
                    <div className="Blog-Upper-left-detail-container">
                        <div>{BLOGS[id - 1].date}</div>
                    </div>
                    <div className="Blog-Upper-left-description-container">
                        <div>{BLOGS[id - 1].description}</div>
                    </div>
                </div>
                <div className="Blog-Upper-right">
                    <div className="Blog-Upper-right-image-container">
                        <img src={BLOGS[id - 1].image} alt="" />
                    </div>
                </div>
            </div>
            <div className='Blog-bottom-container'>
                <div className="Blog-bottom-left">
                    {
                        BLOGS[id - 1].content.primaryPara.map((para) => {
                            return (
                                <div className="Blog-bottom-left-primary-content">
                                    {para}
                                </div>
                            )
                        })
                    }

                    {
                        BLOGS[id - 1].content.secondaryPara.map((content) => {
                            return (
                                <div className="Blog-bottom-left-secondary-content">
                                    <div className="Blog-secondary-title">
                                        {content.title}
                                    </div>
                                    <div className="Blog-secondary-description">
                                        {content.para}
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <div className="Blog-bottom-right">
                    <div className="Blog-bottom-right-title">
                        Recent Blog Post
                    </div>
                    <div className="blog-bottom-right-recent-blog">
                    {
                    BLOGS.slice(0, 6).map((item) => {
                        return <BlogBox2 id={item.id} image={item.image} title={item.title} description={item.description} date={item.date} />
                    })
                }
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Blog