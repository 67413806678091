import { SET_SERVICES } from "../../action/services.action";


export const services = (state = 0, action) => {
    switch (action.type) {
        case SET_SERVICES:
            return action.payload
        default:
            return state
    }
}