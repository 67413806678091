import "./Websites.css";
import SectionOne from "../../../Components/Sectionone/Sectionone";
// import logo from "../../../Assets/landingPage/carousel3-2.png";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import { motion } from 'framer-motion'
import { services_heroSection_Content } from "../../../Model/HeroSection.content"
import SchoolERPImage from "../../../Assets/landingPage/SchoolERP.jpg"
import LandingSection from "../../../Components/LandingSection/LandingSection";
import { useEffect } from 'react';
import ServiceMenu from "../../../Components/ServiceMenuItem/ServiceMenuItem";

const Websites = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}, [])
  const WebsiteContant = services_heroSection_Content.Website

  const WebCardContant =
    [
      {
        image: "https://img.freepik.com/free-vector/mobile-ui-ux-concept-illustration_114360-11697.jpg?t=st=1684938070~exp=1684938670~hmac=efea9b2d29cc3a9c089ee88ab172f8d36a240d27d72d59b8bda3f323012504cb",
        cardtitle: "Web designing",
        carddescription: "Layouts  in-line with organization theme",
        carddescriptionone: "Animated image sliders/flash",
        carddescriptiontwo: "Easy Navigation",
        carddescriptionthree: "Sitemaps"
      }, {
        image: "https://img.freepik.com/free-vector/data-inform-illustration-concept_114360-864.jpg?t=st=1684937915~exp=1684938515~hmac=57c6a19756848e96623946e4506ffc63631c362f6730fdab660581841af8f75c",
        cardtitle: "CMS",
        carddescription: "Proprietary content management system",
        carddescriptionone: "Dynamically add/Edit pages",
        carddescriptiontwo: "Circulars & notices",
        carddescriptionthree: "Adding/updating news"
      },
      {
        image: "https://img.freepik.com/free-vector/mobile-testing-concept-illustration_114360-1564.jpg?t=st=1684937899~exp=1684938499~hmac=d01220e513b5eb2efed910175b3333532aa8142bf07a5ba409bdd0dd179f9f5b",
        cardtitle: "Image Gallery",
        carddescription: "Dynamic image gallery",
        carddescriptionone: "lightbox effects",
        carddescriptiontwo: "Categories with description",
        carddescriptionthree: ""
      }, {
        image: "https://img.freepik.com/free-vector/marketing-consulting-concept-illustration_114360-9027.jpg?t=st=1684937819~exp=1684938419~hmac=82cc764fe372132c919e02e383e07684d404db30662a6e73a3da248e988ed5f9",
        cardtitle: "Online Forms",
        carddescription: "Online admission form",
        carddescriptionone: "Online recruitment form",
        carddescriptiontwo: "Feddback/Complaint",
        carddescriptionthree: "",
      }
    ]
    ;
  return (
    <div className="Website-main-container">
      <LandingSection Background="https://images.pexels.com/photos/245032/pexels-photo-245032.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" Title={WebsiteContant.title} Description={WebsiteContant.description} />

      <ServiceMenu/>
      {/* <SectionOne
        title={WebsiteContant.title}
        description={WebsiteContant.description}
        image={WebsiteContant.image}
        subtitle={WebsiteContant.subtitle}
        subimage={WebsiteContant.subimage}
      /> */}
      <h1>We provide website design services</h1>
      <ContentTemplate description={WebsiteContant.subtitle} image={WebsiteContant.subimage} isReverse={false} />
      <div className="webcontentWrapper">

        {
          WebCardContant.map((web) => {
            return <div className="Websitecard-container">
              <div className="Websitecard-box">
                <div className="websiteCard_image">
                  <img
                    className="AppMockUp"
                    src={web.image}
                    alt=""
                  />
                  <div className="websitecard-title">{web.cardtitle}</div>

                </div>
                <div className="websitecard-text-warp">
                  {/* <div className="websitecard-title">{web.cardtitle}</div> */}
                  <div className="websitecard-description">{web.carddescription}</div>
                  <div className="websitecard-description">{web.carddescriptionone}</div>
                  <div className="websitecard-description">{web.carddescriptiontwo}</div>
                  <div className="websitecard-description">{web.carddescriptionthree}</div>
                </div>
              </div>
            </div>
          })
        }
      </div>

    </div>
  );
};
export default Websites;