const BLOGS = [
  {
    id: 1,
    image:
      "https://img.freepik.com/free-photo/worker-looking-laptop-graphics_1232-1095.jpg?t=st=1685026518~exp=1685027118~hmac=4b46530057e56da122d0f5a023293dde72f17de56a6f84a456ef49a873376e0c",
    title: "5 Reasons why School ERP software is Important",
    description:"In summary, school ERP software plays a crucial role in modernizing and optimizing educational institutions by streamlining administrative tasks, improving communication, centralizing data, enhancing efficiency, and supporting effective teaching and learning practices. It ultimately contributes to the overall growth and success of the school ecosystem. ",
    date: "March 02, 2023",
    content: {
      primaryPara: [
        "An efficient School ERP Software no doubt makes the day to day complex school management hassle-free. Today we are going to discuss about Top 10 School ERP in India to help you to choose the best among the rest. School ERP software makes the management process paperless and less time consuming thus reducing the manpower. Also, all the required data are available at fingertips. With the help of advanced features like E-learning now education can be taken beyond the classroom. Efficient School ERP comes along the facility of a mobile app that bridges the communication gap. Now parents do not need to rush to school every time for any requirement whether it’s about online fee payment, communication with teachers other things, all can be done with just a click. Let us discover the list of Top 10 School ERP Softwares in India to find the one that fits right for your organization.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias."
      ],
      secondaryPara: [
        {
          title: "1. Streamlined Administrative Processes: ",
          para: "School ERP software automates and streamlines various administrative tasks, such as student admissions, attendance tracking, timetable management, fee collection, and more. This reduces manual effort, minimizes errors, and saves time for school staff, allowing them to focus on more strategic activities.",
        },
        {
          title: "2. Enhanced Communication: ",
          para:"School ERP software provides a platform for effective communication between parents, teachers, students, and administrators. It offers features such as messaging, notifications, and portals where parents can access information about their child's progress, attendance, assignments, and other important updates in real-time. This strengthens the school-parent partnership and keeps everyone informed."
        },
        {
          title: "3. Data Centralization and Accessibility: ",
          para:"School ERP systems centralize data related to students, teachers, courses, exams, and other school operations. This centralized database ensures that accurate and up-to-date information is readily accessible to authorized personnel. It facilitates data-driven decision-making and helps in generating various reports and analytics for better insights.",
        },
        {
          title: "4. Improved Efficiency and Productivity: ",
        para:"By automating repetitive tasks and providing a single platform for various operations, school ERP software boosts overall efficiency and productivity. Teachers can quickly enter grades, assignments, and communicate with parents. Administrators can manage resources, track inventory, and monitor finances more efficiently. This leads to a well-organized and smoothly functioning institution."        
        },
        {
          title: "5. Effective Learning Management: ",
          para:"Many school ERP systems include features for learning management, allowing teachers to create and share digital resources, assignments, and assessments. Students can access these materials online, submit assignments electronically, and receive feedback. This fosters a more interactive and engaging learning experience, especially in blended or online learning environments."
        },
        

      ],
    },
  },

  {
    id: 2,
    image:
      "https://img.freepik.com/free-photo/mixed-race-boy-glasses-rising-hand-answer-during-lesson_74855-10276.jpg?t=st=1685026589~exp=1685027189~hmac=321864b40b74b3740158946394bcae85d1abfbdd06d5f58c47f5b82c7a22a1c8",
    title: "How to Improve Student Engagement in A Virtual Classroom",
    description:"Improving student engagement in a virtual classroom requires a combination of effective teaching strategies, technological tools, and creating a supportive online learning environment. Here are some strategies to enhance student engagement:",
    date: "March 02, 2023",
    content: {
      primaryPara: [
        "Remember that a combination of these strategies, tailored to your specific subject, grade level, and student population, will contribute to a more engaging virtual classroom environment. Flexibility, creativity, and a willingness to adapt are key to fostering meaningful interactions and active participation in online learning"
        // "An efficient School ERP Software no doubt makes the day to day complex school management hassle-free. Today we are going to discuss about Top 10 School ERP in India to help you to choose the best among the rest. School ERP software makes the management process paperless and less time consuming thus reducing the manpower. Also, all the required data are available at fingertips. With the help of advanced features like E-learning now education can be taken beyond the classroom. Efficient School ERP comes along the facility of a mobile app that bridges the communication gap. Now parents do not need to rush to school every time for any requirement whether it’s about online fee payment, communication with teachers other things, all can be done with just a click. Let us discover the list of Top 10 School ERP Softwares in India to find the one that fits right for your organization."
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
      ],
      secondaryPara: [
        {
          title: "1. Interactive Content and Activities",
          para:"Utilize a variety of interactive multimedia content, such as videos, simulations, quizzes, and interactive presentations, to keep students engaged and cater to different learning styles."
        },
        {
          title: "2. Engaging Discussion Platforms ",
          para: "Use discussion boards, chat rooms, or video conferencing tools to facilitate meaningful class discussions. Pose thought-provoking questions, encourage students to share their opinions, and moderate discussions to ensure active participation."
        },
        {
          title: "3. Collaborative Group Projects",
          para:"Assign group projects that require students to collaborate virtually. This fosters teamwork, communication, and problem-solving skills while keeping students engaged and accountable."
        },
        {
          title: "4. Real-World Relevance",
          para:"Connect lessons to real-world applications and examples. Highlight how the content relates to current events, personal experiences, or future careers, making the material more engaging and relatable."
        },
        {
          title:"5. Timely Feedback and Assessment",
          para:"Provide prompt and constructive feedback on assignments, quizzes, and assessments. Clear feedback helps students understand their progress and areas for improvement, motivating them to stay engaged and strive for better results.",
        },
        {
          title:"6. Gamification",
          para:"Incorporate elements of gamification, such as badges, leader boards, or rewards, to make the learning experience more enjoyable and competitive. Gamification can enhance motivation and active participation.",
        },
        {
          title:"7. Personalized Learning Paths",
          para:"Tailor the learning experience to individual student needs and preferences. Use adaptive learning technologies or offer optional enrichment activities to cater to various levels of proficiency and interests",
        },
        {
          title:"8. Use of Breakout Rooms",
          para:"In virtual conferencing tools, use breakout rooms for small group discussions or collaborative activities. This encourages more active participation and peer-to-peer interaction. ",
        },
        {
          title:"9. Engaging Visuals and Design",
          para:"Create visually appealing presentations and materials to maintain student interest. Use graphics, infographics, and multimedia elements to convey information in an engaging manner.",
        },
        {
          title:"10. Regular Check-Ins",
          para:"Schedule one-on-one or small group virtual meetings with students to check their progress, address concerns, and provide additional support. Building a personal connection enhances engagement.",
        },
        {
          title:"11. Clear Expectations and Communication",
          para:"Clearly communicate course expectations, objectives, and guidelines for online participation. Regularly update students on class schedules, assignments, and any changes.",
        },
        {
          title:"12. Incorporate Student Choice",
          para:"Provide opportunities for students to make choices about their learning. Offer a selection of assignments, topics, or projects that align with the curriculum, allowing them to pursue areas of interest",
        },
        {
          title:"13. Empower Student Voice",
          para:"Encourage students to share their thoughts, ideas, and suggestions for improving the virtual learning experience. This helps them feel valued and invested in their education.",
        },
      ],
    },
  },

  {
    id: 3,
    image:
      "https://img.freepik.com/free-photo/close-up-person-holding-smartphone_23-2148389008.jpg?t=st=1685026786~exp=1685027386~hmac=b0a44849689f17354194c0c606e3c44bfffefcaddd06c1ae8b6b164c8f6bdb31",
    title: "Top 10 Advantages of School Management System",
    description:"A School Management System (SMS) offers numerous advantages for educational institutions, teachers, students, parents, and administrators. Here are the top 10 advantages of implementing a School Management System:",
    date: "March 02, 2023",
    content: {
      primaryPara: [
          "Implementing a School Management System can revolutionize how educational institutions operate, making processes more efficient, communication more effective, and administration more organized. However, it's important to carefully select an SMS that aligns with the specific needs and goals of your institution."        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
      ],
      secondaryPara: [
        {
          title: "1. Streamlined Administrative Processes",
          para:"An SMS automates and streamlines various administrative tasks, such as admissions, attendance tracking, timetable scheduling, fee management, and more. This reduces manual workloads and enhances efficiency."
        },
        {
          title: "2. Centralized Data Management",
          para:"A School Management System centralizes student, teacher, staff, and course data in one easily accessible location. This ensures accurate and up-to-date information, simplifying data management and reporting."
        },
        {
          title: "3. Improved Communication",
          para:"SMS platforms facilitate effective communication between teachers, parents, students, and administrators through messaging, announcements, and notifications. This enhances transparency and engagement"
         },
        {
          title:"4. Online Enrollment and Admissions",
          para:"With an SMS, schools can offer online enrollment and admissions processes, making it convenient for students and parents to apply and complete necessary documentation."
        },
        {
          title:"5. Enhanced Parental Involvement",
          para:"SMS platforms allow parents to monitor their child's academic progress, attendance, assignments, and communication with teachers. This involvement supports student success."
        },
        {
          title:"6. Efficient Attendance Tracking",
          para:" Automated attendance management reduces the time and effort required for manual attendance taking. It also helps track students' attendance patterns and identify potential issues early on."
        },
        {
          title:"7. Assignment and Grade Management",
          para:"Teachers can easily create, assign, and grade assignments using an SMS. Students and parents can access assignment details, due dates, and grades online."
        },
        {
          title:"8. Timetable and Scheduling",
          para:"SMS systems help generate and manage class timetables, exam schedules, and other events. This minimizes scheduling conflicts and ensures efficient resource allocation."
        },
        {
          title:"9. Financial Management",
          para:"School Management Systems often include features for fee collection, invoicing, and financial reporting. This simplifies financial processes and provides accurate records."
        },
        {
          title:"10. Data Analytics and Reporting",
          para:"SMS platforms offer reporting and analytics tools that provide insights into student performance, attendance trends, financial data, and other key metrics. This data-driven approach supports informed decision-making."
        },
      ],
    },
  },
  {
    id: 4,
    image:"https://img.freepik.com/free-photo/asian-boy-sitting-floor-with-tablet-laptop-stacked-books-using-smartphone_1098-17482.jpg?w=996&t=st=1691747795~exp=1691748395~hmac=a8b9dcc779b6901b4be219045dea7261f982712ea479759eef0e3ac4f1d458a7",
    title: "Digital Adoption in Schools Post COVID: Need of The Hour",
    description:"Digital adoption in schools post-COVID has become an urgent and necessary response to the changing landscape of education. The pandemic has accelerated the integration of technology in education, highlighting the need for schools to embrace digital tools and platforms. Here are five reasons why digital adoption is the need of the hour",
    date: "March 02, 2023",
    content: {
      primaryPara: [
       "However, it's important to note that successful digital adoption requires thoughtful planning, proper training for educators, equitable access to technology, and consideration of potential challenges such as the digital divide and ensuring data privacy. The integration of technology should complement, rather than replace, effective teaching practices and pedagogical approaches."        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
      ],
      secondaryPara: [
        {
          title: "1. Resilience and Continuity",
          para:"The COVID-19 pandemic forced schools to shift to remote and hybrid learning models. Digital adoption ensures that schools are better prepared to handle disruptions and can seamlessly transition between in-person, remote, and blended learning scenarios."
        },
        {
          title: "2. Enhanced Learning Experience",
          para:"Digital tools offer a variety of multimedia resources, interactive content, and online collaboration opportunities that can enrich the learning experience. Students can access a wide range of learning materials tailored to their needs and learning styles."
        },
        {
          title: "3. Personalized Learning",
          para:"Technology enables personalized learning pathways for students. Adaptive learning platforms and data analytics can identify individual learning gaps and strengths, allowing educators to provide targeted interventions and support."
          
        },
        {
          title:"4. Engagement and Motivation",
          para:"Digital tools can make learning more engaging and interactive. Gamified elements, virtual simulations, and multimedia content capture students' attention and enhance motivation to participate actively in their education."
        },
        {
          title:"5. Global Reach and Access",
          para:"Digital adoption can break down geographical barriers, providing students with access to resources and expertise from around the world. Virtual field trips, guest speakers, and collaborative projects can enrich students' understanding of diverse cultures and perspectives."
        },
        {
          title:"6. Skill Development",
          para:"Integrating digital tools into education equips students with valuable digital literacy and technology skills that are increasingly essential in today's workforce."
        },
        {
          title:"7. Teacher Professional Development",
          para:"Digital adoption requires teachers to continuously update their skills, fostering a culture of ongoing professional development. This can lead to a more tech-savvy and adaptable teaching staff."
        },
        {
          title:"8. Efficient Administration",
          para:"Administrative tasks such as attendance tracking, grade management, and communication can be streamlined through digital systems, allowing educators to focus more on teaching and learning"
        },
        {
          title:"9. Parental Engagement",
          para:"Digital tools facilitate better communication between teachers and parents. Parents can track their child's progress, attendance, and assignments, fostering a stronger home-school partnership."
        },
        {
          title:"10. Preparation for the Future",
          para:" The world is rapidly evolving, and technology is an integral part of the future. Schools that embrace digital adoption prepare students for the demands of a technology-driven society and job market"
        },

        
      ],
    },
  },
  {
    id: 5,
    image:"https://img.freepik.com/free-photo/education-academy-certification-curriculum-icon_53876-121144.jpg?w=996&t=st=1691747995~exp=1691748595~hmac=ae3dcdce7239f8d6c37f35aa3c9b50969331e9bedf322e5cb818595ddf771ae1",
    title: "Smart education vs traditional education system",
    description:"Smart education and the traditional education system represent two different approaches to teaching and learning, each with its own set of characteristics, advantages, and challenges. Let's explore the key differences between these two approaches:",
    date: "March 02, 2023",
    content: {
      primaryPara: [
        "Smart education, often referred to as technology-enhanced or digital education, leverages advanced technological tools and methods to enhance the teaching and learning process. It aims to create a more interactive, personalized, and engaging learning environment through the integration of digital resources and platforms. Some features of smart education include:"
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
      ],
      secondaryPara: [
        {
          title: "Technology Integration",
          para:"Smart education heavily relies on technology, including computers, tablets, smartphones, interactive whiteboards, online platforms, and educational apps."
        },
        {
          title: "Personalized Learning",
          para:"Smart education can adapt to individual learning styles and pace. It offers personalized learning pathways, adaptive assessments, and tailored content to meet the unique needs of each student."
        },
        {
          title: "Interactive Content",
          para:"Digital resources, such as multimedia presentations, videos, simulations, and virtual reality experiences, are used to make learning more interactive and engaging."
       },
       {
        title:"Global Connectivity",
        para:"Smart education allows students to connect with peers and experts from around the world, fostering global awareness and collaboration",
       },
       {
         title:"Data Analytics",
         para:"Smart education employs data analytics to track student progress and performance, enabling educators to provide targeted interventions and support"
       },
       {
        title:"Flexibility and Accessibility",
        para:"Learning can take place anytime and anywhere, providing greater flexibility for both students and educators."
       },
       {
        title:"Gamification",
        para:"Gamified elements and rewards can be incorporated to make learning more fun and motivate students to actively participate."
       },
       {
        title:"Traditional Education System"
       },
       {
        title:"Face-to-Face Interaction",
        para:"In-person teacher-student interaction is central to the traditional education system. Classroom-based learning, discussions, and lectures are common.In-person teacher-student interaction is central to the traditional education system. Classroom-based learning, discussions, and lectures are common."
       },
       {
        title:"Structured Curriculum",
        para:"Traditional education follows a structured curriculum with standardized assessments and examinations to evaluate students' progress"
       },
       {
        title:"Limited Personalization",
        para:"Learning experiences are generally the same for all students in a class, with limited room for adapting to individual learning styles and preferences."
       },
       {
        title:"Physical Resources",
        para:"Physical textbooks, chalkboards, and other tangible materials are often used as primary teaching tools."
       },
       {
        title:"Limited Flexibility",
        para:"Learning typically occurs within the confines of a physical classroom, with set schedules and limited flexibility for students"
       },
       {
        title:"Teacher-Centric Approach",
        para:"In the traditional system, teachers play a central role in imparting knowledge, while students primarily listen and take notes"
       },
       {
        title:"Limited Interactivity",
        para:"Interactivity and engagement can vary, depending on teaching methods and individual instructors."
       },
        
      ],
    },
  },
  {
    id: 6,
    image:"https://img.freepik.com/free-photo/confident-teacher-explaining-lesson-pupils_74855-9751.jpg?w=996&t=st=1691748170~exp=1691748770~hmac=9e0325900451a0b86f27d073f0aa16ed73c85b99a0960808cc4ec8b1eb0265cf",
    title: "The importance of ERP software for schools in modern education",
    description:"Enterprise Resource Planning (ERP) software holds significant importance for schools in modern education due to its ability to streamline operations, enhance communication, and improve overall efficiency. Here are key reasons highlighting the importance of ERP software for schools:",
        date: "March 02, 2023",
    content: {
      primaryPara: [
        "In summary, ERP software plays a vital role in modernizing and optimizing school management and operations. It empowers educational institutions to operate more efficiently, communicate effectively, and provide a seamless learning experience for students, teachers, parents, and administrators",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
        // "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum omnis et provident corrupti aperiam perferendis. Mollitia ex voluptatibus eos saepe iusto natus, ab dolorem, eius pariatur laboriosam incidunt earum, a delectus. Facilis modi, voluptates exercitationem laudantium nam quia, eligendi repudiandae velit minus in eveniet. Dolorem aliquid sunt ab repudiandae alias.",
      ],
      secondaryPara: [
        {
          title: "Efficient Administrative Processes",
          para:"ERP software automates and integrates various administrative tasks such as student admissions, attendance tracking, fee collection, timetable scheduling, and staff management. This reduces manual effort, minimizes errors, and improves the overall efficiency of administrative processes."
        },
        {
          title: "Centralized Data Management",
          para:"ERP systems centralize data related to students, teachers, courses, exams, and other school operations. This ensures that accurate and up-to-date information is readily accessible to authorized personnel, facilitating data-driven decision-making."
        },
        {
          title: "Enhanced Communication",
          para:"ERP software provides a platform for effective communication between teachers, students, parents, and administrators. It offers features such as messaging, notifications, and portals where parents can access information about their child's progress, attendance, assignments, and other updates in real-time."
          
        },
        {
         title:"Data-Driven Insights",
          para:"ERP systems generate reports and analytics that offer insights into student performance, attendance patterns, financial data, and other key metrics. This data-driven approach allows schools to identify trends, make informed decisions, and continuously improve their processes."
        },
        {
          title:"Resource Optimization",
           para:"ERP software helps schools optimize resource allocation by efficiently managing facilities, staff, and inventory. This ensures that resources are utilized effectively, leading to cost savings and improved operations."
         },
         {
          title:"Personalized Learning",
           para:"Some ERP systems offer learning management features that allow teachers to create and share digital resources, assignments, and assessments. This fosters personalized learning experiences and supports various teaching methods, including blended and online learning."
         },
         {
          title:"Parental Engagement",
           para:"ERP systems provide parents with access to their child's academic progress, attendance records, and communication with teachers. This promotes parental involvement in their child's education and strengthens the home-school partnership"
         },
         {
          title:"Streamlined Financial Management",
           para:"ERP software includes features for fee collection, financial reporting, and budget management. It helps schools maintain accurate financial records, track expenses, and ensure transparent financial operations"
         },
         {
          title:"Security and Data Privacy",
           para:"ERP systems often come with security features to protect sensitive information, ensuring data privacy and compliance with regulations such as GDPR or FERPA."
         },
         {
          title:"Scalability and Adaptability",
           para:"ERP software can scale with the growth of the school and adapt to changing educational needs and technological advancements"
         },
         {
          title:"Integration Capabilities",
           para:"Many ERP solutions can integrate with other educational tools, such as learning management systems (LMS), communication platforms, and assessment tools, creating a cohesive ecosystem"
         },
         {
          title:"Future-Proofing Education",
           para:"As technology continues to shape the future of education, ERP software helps schools remain competitive and relevant by embracing digital transformation and innovation."
         },
      ],
    },
  }
];

export default BLOGS;
