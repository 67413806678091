import AboutImage from "../Assets/landingPage/AboutUs.jpg";
import ContactUsImage from "../Assets/landingPage/ContactUs.jpg";
import ClientImage from "../Assets/landingPage/Client.jpg";
import logo from "../Assets/landingPage/carousel-2.png";


export const about_heroSection_Content = {
  title:"About Generic Software Solutions Private Limited",
  description:
    "Our management system is intended to improve efficiency in educational institutions by streamlining administrative activities.",
  image: AboutImage,
  subtitle:
    "Generic has a philosophy of making quality school management-inclusive, simple, affordable and economical at a fractional of the cost. It was founded in 2009 by a group of software professionals having expertise in the domain, with vision, dreams and strong commitments. In this journey of more than 14 years with a humble beginning and sincere attitude, we were able to make inroads in this highly demanding, challenging and result-oriented industry. We are already aiming stars and discovering new avenues to reach customers like you.Generic Software Solutions Pvt Ltd is a dynamic and innovative IT company specializing in providing cutting-edge software solutions and services to clients across various industries. ",
  subimage:
    "https://img.freepik.com/free-photo/children-having-technology-education-class_23-2149123767.jpg",
    subtitleone:"It is a provider of innovative software solutions for businesses across various industries. With a strong focus on delivering high-quality products and exceptional customer service, we help our clients streamline their operations, improve efficiency, and achieve their digital transformation goals.We are here for the long run. We believe in partnership with our employees, customers, vendors, governing bodies and other stakeholders to strengthen the ecosystem over a long-time horizon.With widespread acceptance and appreciation, we got references, Educational Institutions have referred us to more other Educational Institutions. We have made ample of clients which gives us confidence to do our best for the industry.",
    subimageone:"",
    subtitletwo:"We build optimal solutions focusing on improving learning outcomes. We are austere. We guard and conserve the company's resources with the same vigilance as we would guard and conserve our own. We have established ourselves as a trusted partner for organizations seeking innovative software solutions. Our wide reach allows us to serve clients from various industries, including education, healthcare, finance, manufacturing, and more. We continuously strive to expand our presence and forge strategic partnerships to better serve our clients and cater to their evolving needs.",
    subimagetwo:""
};

export const contact_heroSection_Content = {
  title: "CONTACT US",
  description:
    "For any inquiries or to learn more about our School ERP solutions, please feel free to contact us. We're here to assist you.",
  image: ContactUsImage,
  subtitle:
    "Welcome to our School ERP platform, a leading provider of comprehensive solutions for educational institutions. Our mission is to empower schools with advanced technology that simplifies administrative tasks, enhances communication, and improves overall efficiency. With our user-friendly interface and robust features, we offer a seamless experience for administrators, teachers, parents, and students. From student admissions and attendance management to grade tracking and resource allocation, our ERP system streamlines school operations, saves time, and enhances collaboration. We are dedicated to supporting the success of educational institutions by providing innovative solutions that foster growth, productivity, and a seamless learning environment. Contact us today to learn more about how our School ERP can benefit your institution.",
  subimage:
    "https://img.freepik.com/free-photo/smiling-woman-headset-presentation-something_329181-11710.jpg?t=st=1684930950~exp=1684931550~hmac=021fc5eae97fc9e74a3778b6b1b46b2c69b15b9f203cc7bd3fe09e831b38ff62",
  };

export const clients_heroSection_Content = {
  title: "CLIENTS",
  description:
    "Our ERP clients range from small businesses to large enterprises across various industries, benefiting from streamlined operations, improved efficiency, and enhanced decision-making capabilities",
  image: ClientImage,
  subtitle:
    "Our ERP client base spans a diverse range of industries, including manufacturing, retail, healthcare, finance, and more. From small businesses to large enterprises, our ERP solutions cater to the unique needs of each client. Our clients rely on our ERP systems to streamline their operations, optimize resource utilization, improve productivity, and enhance decision-making capabilities. By centralizing data, automating processes, and providing real-time insights, our ERP solutions empower our clients to stay ahead of the competition and drive growth. We pride ourselves on building long-lasting partnerships with our clients, delivering customized ERP solutions that address their specific business challenges and contribute to their overall success.",
  subimage:
    "https://img.freepik.com/free-photo/businessman-businesswoman-shaking-hands-after-making-profita_1163-5263.jpg?t=st=1684931771~exp=1684932371~hmac=8f5e3c21cbf0ee101e53035ff47fc970467a05972c2e973ce9c843ddbb714358",
};

export const services_heroSection_Content = {
  Elearning: {
    title: "E - LEARNING",
    description:
    "Online learning, or e-learning, is essentially learning that takes place online using electronic media. This makes learning more practical. Learning is now readily available at one's fingertips and is accessible via a variety of electronic devices, including smartphones, tablets, and laptops, depending on the users preference.",
    // image:"https://img.freepik.com/free-photo/freelance-woman-desk-writing-agenda_23-2148604044.jpg?t=st=1684927961~exp=1684928561~hmac=472a588149cd419c85a07f3a556dfeb34649fdf43e24b50a08b5c970479bca4e",
    image:
      "https://img.freepik.com/free-photo/happy-young-woman-sitting-floor-using-laptop-gray-wall_231208-11472.jpg?t=st=1684927961~exp=1684928561~hmac=5c1043924d7624a8a5aaf7c07c1d067642876def19413ef12e7a52da908226bd",
    subtitle:
"How could we fall behind in learning in this internet era where the majority of things are now available on digital platforms? EduAppz is dedicated to developing effective  management strategies that will advance learning to the highest possible degree. As a result, e-learning software for schools/colleges was developed to reduce the obstacles to advancement in the field of education. Let's start by giving a quick overview of the e-learning system."  ,
  subimage:
      "https://img.freepik.com/free-photo/side-view-businesswoman-working-with-headphones-laptop_23-2148788874.jpg?t=st=1684927961~exp=1684928561~hmac=7b66516502393d3893ef6b35fb4289206cddbc807117a50ce6137c47931d3fb5",
  subimageone: "https://img.freepik.com/free-photo/side-view-businesswoman-working-with-headphones-laptop_23-2148788874.jpg?t=st=1684927961~exp=1684928561~hmac=7b66516502393d3893ef6b35fb4289206cddbc807117a50ce6137c47931d3fb5",  subtitleone:"Many elements of teaching and learning that formerly seemed unachievable are now possible because to e-learning. We can continuously monitor student performance, resource availability, the frequency of conducted lessons, and even the attendance of students who are attending the classes using school e-learning software.Furthermore, with dependable e-learning software for , tests might be administered online with immediate results. Let's examine some of the ways that employing ERP software in a web-based online class may benefit students the most and simplify the operation of schools/colleges",
  subimagetwo:"Many elements of teaching and learning that formerly seemed unachievable are now possible because to e-learning. We can continuously monitor student performance, resource availability, the frequency of conducted lessons, and even the attendance of students who are attending the classes using school e-learning software.Furthermore, with dependable e-learning software for , tests might be administered online with immediate results. Let's examine some of the ways that employing ERP software in a web-based online class may benefit students the most and simplify the operation of schools/colleges.",
  subimagetwo:"https://img.freepik.com/free-photo/side-view-businesswoman-working-with-headphones-laptop_23-2148788874.jpg?t=st=1684927961~exp=1684928561~hmac=7b66516502393d3893ef6b35fb4289206cddbc807117a50ce6137c47931d3fb5"  
   
},
  Rfid: {
    title: "Biometric/RFID System",
    description:
      "The EduAppz safety solution tracked pupils' presence on the school grounds using cloud-based Radio Frequency Identification (RFID) technology.",
    image:
      "https://img.freepik.com/free-photo/smart-watch-contactless-cashless-payment_53876-97186.jpg?t=st=1684925760~exp=1684926360~hmac=5234530e582092094d40c47bd4c60f75efab37747f8450bee201183037af01d6",
    subtitle:"A new safety element that EduAppz implemented ensures the child's safety on school grounds. The EduAppz safety system tracked student attendance on the school grounds using cloud-based Radio Frequency Identification (RFID) technology. The method makes sure that pupils are watched as soon as they enter the building. An SMS will be sent instantly to the parents' mobile device as soon as the student goes through the Smart Gate Reader. SMS system that instantly sends an SMS notification when a student enters or exits the school/college. The system offers a well-designed web interface that can track and report data in real time. The method does away with the requirement for human intervention to run the hardware. The recorded attendance is instantly recorded. With graphical dashboards and powerful MIS reports, the online login is equipped. An easy-to-use biometric student attendance system for schools is EduAppz’s RFID system. It is one of the best online systems for tracking students' attendance in classrooms.",
    subimage:
      "https://img.freepik.com/free-photo/person-paying-with-its-credit-card_23-2149167246.jpg?t=st=1684926337~exp=1684926937~hmac=e149bfccae9a4a7047bbd810e9dad50435bc9c2edf77f414bf60f847d4bfffb3",
  subtitleone:"The RFID attendance system with SMS notification has without a doubt elevated schooling to the next secure level with the introduction of several smart technologies in this emerging era. Child security has been every parent's top concern as a result of the daily rise in crime. Since their children leave for school, parents often worry constantly about their safety. Generic Software Solutions Private Limited  has developed an RFID-based school attendance monitoring system to allay these concerns.By informing the parents of a child's boarding and disembarking from the school bus as well as the student's arrival or departure from the school, this affordable solution ensures the safety and security of the children. It is a simple way to keep track of a student's presence or absence at school premises. Let's read the short below to learn more about the RFID student monitoring system.",
  subimageone:"https://assets.website-files.com/5f7f21e12602d918888dce1b/63062aa3ecbd8285aa060bfb_What-is-Radio-Frequency-Identification-(RFID).webp"
    },
  Schoolmanagement: {
    title: "SCHOOL MANAGEMENT",
    description:
      "When discussing school management software or School ERP, many people often consider it merely for reporting purposes.",
    image: "https://img.freepik.com/free-photo/close-up-kid-while-having-technology-education-class_23-2149123763.jpg?t=st=1684927426~exp=1684928026~hmac=e260b3be937a28f513f711aab28ba031b06689b40153c9c26162cd4c3a7bedc8",
    subtitle: "However, a comprehensive school management software is much more than that; it caters to the specific needs and requirements of a school, automates processes, and enhances efficiency, saving valuable time. In today's educational landscape, schools rely on software that aids their day-to-day operations. Yet, a complete software solution should be fully functional, providing detailed insights to improve the productivity of various school departments, enabling better planning and optimized resource utilization.In the past, school management software was limited to operational tasks. However, recent innovations have introduced effective communication portals, making the software's output accessible to various stakeholders, including parents, students, management staff, and teachers.",
    subimage: "https://img.freepik.com/free-photo/girl-student-hand-hug-book_1150-4131.jpg?w=996&t=st=1691324095~exp=1691324695~hmac=bfd8fde5beb3c556889a34cf6366199f6f827d50709722744a4a33742f777d78",
    subtitleone: "This robust software brings all stakeholders onto one platform, offering real-time updates through a convenient mobile app. Schools with superior management and coordination tend to grow stronger and reach new heights. In the education sector, those with reliable School Management Software take the lead.With numerous school software options available in the market, it can be overwhelming to find the best fit for your needs. To alleviate your concerns, Generic Software Solutions brings you India's most valued and first talking ERP school software, elevating learning beyond traditional methods and streamlining day-to-day processes. Now, let's delve into a brief explanation of the school software, its features, and the advantages of using this reliable management software for schools.",
    subimageone: "https://img.freepik.com/free-photo/serious-teacher-checking-assignment-two-students_1262-15174.jpg?t=st=1684927426~exp=1684928026~hmac=b4c221098351cd4f933ed2a940a3748dcbf02c6e90131c48d401760ab8f5eb36",
    subtitletwo: "The software designed for digitally monitoring and carrying out various school daily operations on a single platform is basically the school management software. Everything is achievable with dependable school management software, from monitoring children to all staff members. Large amounts of data are generated by the numerous, intricate everyday procedures at school. Utilising intelligent school administration software lessens the strain of many administrative procedures while also pacing the rate of advancement. In addition to this, there are several management-dependent areas of school operations, such as admission, communication regarding student attendance, fee management, and payroll administration, that may be handled without difficulty with the aid of sophisticated software. ",
    subimagetwo: "https://img.freepik.com/free-photo/sideways-school-boy-copy-space_23-2148764003.jpg?w=1060&t=st=1691324021~exp=1691324621~hmac=4f8b80baa09db92bd2823cedd5bf9c67ff73a91cbd75a95e74c2801054367adc",
  },
  CollegeManagement: {
    title: "COLLEGE MANAGEMENT",
    description:
      "Whenever we talk about college management software or College ERP, most of the time people consider college management software more or less only for reporting purposes.",
    image: "https://img.freepik.com/free-photo/close-up-kid-while-having-technology-education-class_23-2149123763.jpg?t=st=1684927426~exp=1684928026~hmac=e260b3be937a28f513f711aab28ba031b06689b40153c9c26162cd4c3a7bedc8",
    subtitle: "A college requires management software catering to its specific needs and requirements. A college management software that is automated and helps individuals to increase their efficiency, saving their time. Currently, in today’s scenarios, colleges are using software that helps them with their day-to-day operations. However, a complete software solution should be fully functional to provide the details required to enhance the productivity of various departments of the college, enabling them to plan and optimize resource utilization effectively.",
    subimage: "https://img.freepik.com/free-photo/group-six-indian-businessman-suits-posed-outdoor-winter-day-europe-looking-laptop_627829-1420.jpg?w=996&t=st=1691324222~exp=1691324822~hmac=579f07a5e2cdd46ded564d6c12950a88cafa04b38092da4078e566f1ec407942",
    subtitleone: "In earlier days, college management software was just for managing college operations. But nowadays, new innovations in good software have introduced an effective communication portal by which the output of the college management software is visible to various stakeholders. Whether it be students, faculty, management staff, or parents, a robust software brings all the stakeholders under one platform, providing real-time updates through a mobile app. Those colleges that have better management and coordination tend to grow stronger and reach new heights. ",
    subimageone: "https://img.freepik.com/free-photo/focused-students-using-tablet-discussing-information_1262-14929.jpg?w=996&t=st=1691324276~exp=1691324876~hmac=c958681ec5d8c4c42e25f98112f58fab0750f6aa38d8651d861fb05c332e75dd",
    subtitletwo: "In the education sector, colleges equipped with reliable College Management Software take the lead. There are many college software solutions available in the market currently, and in the quest of finding the best among the rest, some tend to try-out software that hardly stands up to their requirements. In order to put an end to your worries, we, at Generic Software Solutions, have come up with the most valued and advanced College ERP software, taking learning beyond and ensuring hassle-free day-to-day processes. Let us begin with a brief explanation about college software, its features, and the advantages of using a reliable management software for colleges. ",
    subimagetwo: "https://img.freepik.com/free-photo/serious-teacher-checking-assignment-two-students_1262-15174.jpg?t=st=1684927426~exp=1684928026~hmac=b4c221098351cd4f933ed2a940a3748dcbf02c6e90131c48d401760ab8f5eb36",
  },
  IndustryManagement: {
    title: "Industry Management Sofyware ",
    description:
      "Generic Software Solutions provides a comprehensive Industry Management Software system designed to streamline and optimize production processes, documenting the entire transformation journey from raw materials to finished products",
    image: "https://img.freepik.com/free-photo/close-up-kid-while-having-technology-education-class_23-2149123763.jpg?t=st=1684927426~exp=1684928026~hmac=e260b3be937a28f513f711aab28ba031b06689b40153c9c26162cd4c3a7bedc8",
    subtitle: "Our software serves multiple purposes, empowering businesses with real-time decision-making capabilities, plant optimization for increased production, and efficient control over resource consumption, personnel, machinery, and transport services. With Generic Software Solutions' Industry Management Software, you gain the ability to acquire, store, and analyze all production data seamlessly, irrespective of the manufacturing environment. Our centralized web-based platform offers a single point of access through any standard web browser, making data management and analysis convenient and accessible.",
    subimage: "https://img.freepik.com/free-photo/information-technology-connection-graphics-concept_53876-121040.jpg?w=996&t=st=1691324495~exp=1691325095~hmac=45f35996c50a168a14f378e7ebb40ff80b9e696631847a3d5e723e77918c8ee4",
    subtitleone: "In the era of Industry 4.0, our software takes connectivity to the next level by seamlessly integrating with ERPs and various third-party applications. This interconnectivity enables our clients to access a vast array of products and technologies, facilitating a cohesive and cohesive ecosystem for efficient industrial management.One of the standout features of our Industry Management Software is its powerful data analysis capabilities. The system can intelligently analyze the vast volumes of data collected according to the unique needs of each customer.",
    subimageone: "https://img.freepik.com/free-photo/people-office-analyzing-checking-finance-graphs_23-2150377156.jpg?w=996&t=st=1691324634~exp=1691325234~hmac=f0c4d69e74d14a690fc06f482b7bf263938db6b4ce796410fbb80b8fdc57e8f4",
    subtitletwo: "It presents the insights in a visually engaging manner, using various types of graphs, including Pareto charts, cause trees, alarm tops, and customized reports that precisely display the information required by the customer.At Generic Software Solutions, we are committed to providing cutting-edge software solutions that empower industries to thrive in the dynamic and competitive landscape. Our Industry Management Software brings together advanced technology and intuitive design, equipping businesses with the tools they need to optimize their production processes and stay ahead in the ever-evolving market.",
    subimagetwo: "https://img.freepik.com/free-photo/people-office-analyzing-checking-finance-graphs_23-2150377138.jpg?w=996&t=st=1691324582~exp=1691325182~hmac=b4c923bde74b09abf531afb8cde75e06ea3514e65434bc33476d6e8fc1312f9f",
  },
  MobileappService: {
    title: "Mobile Apps Service",
    description:
      "Connects teachers, parents, students, and school administration to facilitate speedier communication amongst them.",
    image: "https://img.freepik.com/free-photo/close-up-kid-while-having-technology-education-class_23-2149123763.jpg?t=st=1684927426~exp=1684928026~hmac=e260b3be937a28f513f711aab28ba031b06689b40153c9c26162cd4c3a7bedc8",
    subtitle: "The Digital era brings with it the need for diverse devices in order to maximise benefits for everyone. The smartphone is the only technological gadget that the majority of people have access to among all of those that are accessible worldwide. As a result, it creates a need for mobile apps for school management to keep track of every student's status. With only a swipe, our EduAppz Mobile App makes it possible to do practically all of the required schoolwork from any location at any time. One smartphone app may be used to complete all daily duties associated to school, college including paying tuition, reviewing circulars, looking up assignments, connecting to live classrooms, using e-learning, and tracking student progress.",
    subimage: "https://img.freepik.com/free-photo/man-using-smartphone-digital-remix_53876-104964.jpg?w=996&t=st=1691324718~exp=1691325318~hmac=66c38e23205d8d88b16569ce0cb7065f91a36af6297a6e702982f86112ba88f3",
    ttileone:"What is mobile app",
    subtitleone: "In the era of Industry 4.0, our software takes connectivity to the next level by seamlessly integrating with ERPs and various third-party applications. This interconnectivity enables our clients to access a vast array of products and technologies, facilitating a cohesive and cohesive ecosystem for efficient industrial management.One of the standout features of our Industry Management Software is its powerful data analysis capabilities. The system can intelligently analyze the vast volumes of data collected according to the unique needs of each customer.",
    subimageone: "https://img.freepik.com/free-photo/businessman-holding-blank-screen-smartphone_53876-104965.jpg?w=996&t=st=1691324775~exp=1691325375~hmac=14861cdaaedc8ae7d7a7e166d7105843da1683d224cf9ffb97dce5950f4dd160",
    subtitletwo: "It presents the insights in a visually engaging manner, using various types of graphs, including Pareto charts, cause trees, alarm tops, and customized reports that precisely display the information required by the customer.At Generic Software Solutions, we are committed to providing cutting-edge software solutions that empower industries to thrive in the dynamic and competitive landscape. Our Industry Management Software brings together advanced technology and intuitive design, equipping businesses with the tools they need to optimize their production processes and stay ahead in the ever-evolving market.",
    subimagetwo: "https://img.freepik.com/free-photo/close-up-person-holding-smartphone_23-2148389008.jpg?w=996&t=st=1691324861~exp=1691325461~hmac=7ceda41f533c36a58dadb95c513c9aedf0ebde4d5635389bd1a93f3f28da793b",
  },
  SEO: {
    title: "SEO/SMM",
    description:
      "Boost your online presence with our SEO and SMM services. Drive targeted traffic to your website, increase brand visibility, and engage with your audience effectively.",
    image:"https://img.freepik.com/free-photo/business-chart-visual-graphics-report-concept_53876-120324.jpg?t=st=1684926977~exp=1684927577~hmac=ecbd0ddc594dff3330ab9fb0a06f09e4917eeb92292ea471b1ed34db8b2ecd93",
    subtitle: "Elevate your online presence and grow your business with our comprehensive SEO (Search Engine Optimization) and SMM (Social Media Marketing) services. Our expert team will optimize your website to rank higher on search engine results, driving targeted organic traffic to your site. We'll also develop a tailored social media strategy to enhance your brand visibility, engage with your audience, and generate leads. By leveraging the power of SEO and SMM, we'll help you reach your target audience, increase conversions, and maximize your online success. Stay ahead of the competition and establish a strong digital presence with our proven SEO and SMM solutions.",
    subimage: "https://img.freepik.com/free-photo/student-id-card-identification-data-information-education-concept_53876-132190.jpg?t=st=1684900112~exp=1684900712~hmac=ef71a1956be7fe008191f10e9bf33b104175e158a3a64042faa0e36c3514598b",
  },
  Website:{
    title: "Today, having a significant online presence is more than just necessary—it's crucial.",
    description:
      "Although it acts as the centrepiece of your products, services, profile, and in fact your entity as a corporate, organisation, business, or even as an individual, this necessity must be handled with the utmost care, accuracy, and functionality.Any website we design and construct at EduAppz is envisioned as a key tool that supports your organisational objectives and powerfully communicates ",
    image: "https://thumbor.forbes.com/thumbor/fit-in/1290x/https://www.forbes.com/advisor/wp-content/uploads/2023/07/designing-a-website.jpeg.jpg",
    subtitle: "Any website we design and construct at EduAppz is envisioned as a key tool that supports your organisational objectives and powerfully communicates your vision. While we place a great value on design aesthetics, we also make sure that your site's navigation is quick, easy, and even pleasant. Each part should be clearly marked and navigable.",
    subimage: "https://img.freepik.com/free-photo/website-hosting-concept-with-new-computer_23-2149406781.jpg",
  }
};
