export const siteMap = {
    HomePage: {
      title: "Home",
      path: "/",
      description: "Generic's home page",
    },
    ProductPage: {
      title: "Product",
      path: "/product",
      description: "Generic's product page",
    },
    ProductDetailPage: {
      title: "ProductDetail",
      path: "/product-details",
      description: "Generic's product-detail page",
    },
    GalleryPage: {
      title: "Gallery",
      path: "/gallery",
      description: "Generic's gallery page",
    },
    CertificationPage: {
      title: "Certification",
      path: "/certification",
      description: "Generic's certification page",
    },
    AboutPage: {
      title: "About",
      path: "/about",
      description: "Generic's About page",
    },
    ContactPage: {
      title: "Contact",
      path: "/contact",
      description: "Generic's Contact page",
    },
    ErpPage:{
      title:"EducationalErp",
      path: "/EducationalErp",
      description: "Generic's Erp Page",
    },
    WebsitePage:{
      title:"Website",
      path: "/Website",
      description: "Generic's Website Page",
    },
    SchoolmanagementPage:{
      title:"SchoolManagement",
      path:"/SchoolManagement",
      description:"Generic's SchoolManagement Page",
    },
    CollegemanagementPage:{
      title:"CollegeManagement",
      path:"/CollegeManagement",
      description:"Generic's CollegeManagement Page",
    },
  IndutrialmanagementPage:{
      title:"IndustrialManagement",
      path:"/IndustrialManagement",
      description:"Generic's IndustrialManagement Page",
    },
    MobileappServicePAge:{
      title:"MobileappService",
      path:"/MobileappService",
      description:"Generic's MobileappService Page",
    },
    SeoPage:{
      title:"Seo",
      path:"/Seo",
      description:"Generic's SEO Page",
    },
    ElearningPage:{
      title:"Elearning",
      path:"/Elearning",
      description:"Generic's E-Learning Page",
    },
    RfidPage:{
      title:"Rfid",
      path:"/Rfid",
      description:"Generic's RFID Page",
    },
    ClientPage:{
      title:"Client",
      path:"/Clients",
      description:"Generic's Clients Page",
    },
    ContactUsPage:{
      title:"Contact",
      path:"/Contact",
      description:"Generic's ContactUs Page"
    },
    BlogPage:{
      title:"BlogList",
      path:"/BlogList",
      description:"Generic's BlogList Page"
    },
    BlogContentPage:{
      title:"Blog",
      path:"/Blog",
      description:"Generic's Blog Page"
    }
  };

  