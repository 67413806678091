import "./ContactUs.css"
import { useLottie } from "lottie-react";
import ContainerIntro from "../../../Components/ContainerIntro/ContainerIntro";
import Arrow from "../../../Assets/lottie/arrow.json";
import { useFormik } from "formik";
import * as Yup from "yup";

const ContactUs = () => {

  // ARROW LOTTIE HANDLED HERE
  const ArrowLottie = () => {
    const options = {
      animationData: Arrow,
      loop: true,
      autoplay: true,
    };
    const { View } = useLottie(options);
    return View;
  };

  const formik = useFormik({
    initialValues: {
      Name: "",
      Mobile: "",
      Email: "",
      School: "",
      Website: "",
      // Designation: "",
      City: "",
      Message: ""
    },

    validationSchema: Yup.object({
      Name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      Mobile: Yup.number().min(10, "Must be 10 characters").required("Required"),
      Email: Yup.string().email("Invalid email address").required("Required"),
      School: Yup.string().required("Required"),
      Website: Yup.string(),
      // Designation: Yup.string().required("Required"),
      City: Yup.string().required("Required"),
      Message: Yup.string().required("Required"),
    }),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });


  return (
    <div style={{height:"fit-content", width:"95%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", gap:"1vh"}}>
    
      <ContainerIntro
          title="CONTACT US"
          subtitle="We're Always ready"
          description="Phone Number : +91 9899956677 | | Email : sales@genericsoftware.in"
        />
    <div className="ContactUs-main-container">
      <div className="ContactUs-left-container">
        {/* <div className="ContactUs-left-title">
          CONTACT US
        </div>
        <div className="ContactUs-left-address">
          We're Always ready
        </div>
        <div className="Contact-left-content-container">
          <div className="ContactUs-left-content">
            110-111 Laxmi Tower, Commercial Complex Azadpur, Delhi - 11003
          </div>
          <div className="ContactUs-left-content">
            +91 8700763221
          </div>
          <div className="ContactUs-left-content">
            sales@genericsoftware.in
          </div>
        </div> */}
        <div className="ContactUs-left-gif">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.2976216427687!2d77.17636107450667!3d28.710650275622132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d021d9292400b%3A0xbf3680a84ee29fba!2sLaxmi%20Tower%2C%20Azadpur%20Commercial%20Complex%2C%20Commercial%20Complex%2C%20Gopal%20Nagar%2C%20Model%20Town%2C%20Delhi%2C%20110033!5e0!3m2!1sen!2sin!4v1689190167004!5m2!1sen!2sin" width="80%" height="80%" style={{border:0, borderRadius:"10px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          {/* <img src="https://images.pexels.com/photos/821754/pexels-photo-821754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" /> */}
          {/* <ArrowLottie /> */}
        </div>
      </div>
      <div className="ContactUs-right-container">
        <div className="ContactUs-right-form">
          <form className="form-container" onSubmit={formik.handleSubmit}>
            <div className="contact-form-input-warp-home">
              
              <input
                className="form-input-home"
                id="Name"
                name="Name"
                type="text"
                placeholder="Full Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Name}
              />
              {formik.touched.Name && formik.errors.Name ? (
                <div className="contact-error">{formik.errors.Name}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              {/* <label className="label-home" htmlFor="Mobile">
                Mobile
              </label> */}
              <input
                className="form-input-home"
                id="Mobile"
                name="Mobile"
                type="text"
                placeholder="Mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Mobile}
              />
              {formik.touched.Mobile && formik.errors.Mobile ? (
                <div className="contact-error">{formik.errors.Mobile}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              {/* <label className="label-home" htmlFor="School">
                School/Organization
              </label> */}
              <input
                className="form-input-home"
                id="School"
                name="School"
                type="text"
                placeholder="School/Organization"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.School}
              />
              {formik.touched.School && formik.errors.School ? (
                <div className="contact-error">{formik.errors.School}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              {/* <label className="label-home" htmlFor="Website">
                Website
              </label> */}
              <input
                className="form-input-home"
                id="Website"
                name="Website"
                type="text"
                placeholder="Website"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Website}
              />
              {formik.touched.Website && formik.errors.Website ? (
                <div className="contact-error">{formik.errors.Website}</div>
              ) : null}
            </div>

            {/* <div className="contact-form-input-warp-home">
              <input
                className="form-input-home"
                id="Designation"
                name="Designation"
                type="text"
                placeholder="Designation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Designation}
              />
              {formik.touched.Designation && formik.errors.Designation ? (
                <div className="contact-error">{formik.errors.Designation}</div>
              ) : null}
            </div> */}

            <div className="contact-form-input-warp-home">
              {/* <label className="label-home" htmlFor="City">
                City
              </label> */}
              <input
                className="form-input-home"
                id="City"
                name="City"
                type="text"
                placeholder="City"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.City}
              />
              {formik.touched.City && formik.errors.City ? (
                <div className="contact-error">{formik.errors.City}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              {/* <label className="label-home" htmlFor="Message">
                Message
              </label> */}
              <textarea
                className="form-text-home"
                id="Message"
                name="Message"
                type="text"
                placeholder="Message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Message}
              />
              {formik.touched.Message && formik.errors.Message ? (
                <div className="contact-error">{formik.errors.Message}</div>
              ) : null}
            </div>

            <button className="contact-submit-home" type="submit">
              SUBMIT
            </button>

          </form>
        </div>
      </div>
    </div>
    </div>
  )
}
export default ContactUs