import "./BlogBox.css"
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";


export const BlogBox = ({id, image, title, description, date}) => {

    const navigate = useNavigate();

   const handleClick = () => {
    navigate(siteMap.BlogContentPage.path, {state:{id:id}}, { replace: true });
   }
   
   
  return (
    <div className="BlogBox-main-container">
        <div className="BlogBox-image-container">
            <img src={image} alt="" />
        </div>
        <div className="BlogBox-title-container">
            <div className="BlogBox-title">
                {title}
            </div>
        </div>
        <div className="BlogBox-description-container">
            <div className="BlogBox-description">
                {description}
            </div>
        </div>
        <div className="BlogBox-details-container">
            <div className="BlogBox-Date">
                {date}
            </div>
            <div className="BlogBox-ReadMore" onClick={()=>{handleClick()}}>
                Read More
            </div>
        </div>
    </div>
  )
}

export const BlogBox2 = ({id, image, title, description, date}) => {

const navigate = useNavigate();

   const handleClick = () => {
    navigate(siteMap.BlogContentPage.path, {state:{id:id}}, { replace: true });
   }
   
   
  return (
    <div className="BlogBox2-main-container" onClick={()=>{handleClick()}}>
        <div className="BlogBox2-image-container">
            <img src={image} alt="" />
        </div>
        <div className="BlogBox2-details_container">
        <div className="BlogBox2-title-container">
            <div className="BlogBox-title">
                {title}
            </div>
        </div>
        <div className="BlogBox2-description-container">
            <div className="BlogBox-description">
                {description}
            </div>
        </div>
        </div>
    </div>
  )
}
