import "./ContainerIntro.css"
import { motion } from "framer-motion"


const ContainerIntro = ({ title, subtitle, description }) => {
    const mobile_res = window.screen.width <= 720
    const title_attributes = !mobile_res ? {
        initial:{ x: -200 },
        whileInView:{x: 0, transition: { duration: 2 } }
    } : {
        initial:{ x: 0 },
    }
    const subTitle_attributes = !mobile_res ? {
        initial:{ x: 200 },
        whileInView:{ x: 0, transition: { duration: 2 } }
    } : {
        initial:{ x: 0 },
    }
    const description_attributes = !mobile_res ? {
        initial:{ x: -200 },
        whileInView:{ x: 0, transition: { duration: 2 } }
    } : {
        initial:{ x: 0 },
    }

    return (
        <>
            <motion.div className="Intro-title-container" {...title_attributes}>
                <div className="Intro-title">
                    {title}
                </div>
            </motion.div>
            <motion.div className="Intro-subtitle-container" {...subTitle_attributes}>
                <div className="Intro-subtitle">
                    {subtitle}
                </div>
            </motion.div>
            <motion.div className="Intro-description-container" {...description_attributes}>
                <div className="Intro-description">
                    {description}
                </div>
            </motion.div>
        </>
    )
}
export default ContainerIntro