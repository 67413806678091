import React, { useState, useRef } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Sidebar } from 'primereact/sidebar';
import "./SlideForm.css";
import { useLottie } from "lottie-react";

import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import Arrow from "../../Assets/lottie/arrow.json";
import Divider from '@mui/material/Divider';


const SlideForm = () => {
    const [visible, setVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(1);
    const toast = useRef(null);
    const [formStep, setFormStep] = useState(1);

    // ARROW LOTTIE HANDLED HERE
    const ArrowLottie = () => {
        const options = {
            animationData: Arrow,
            loop: true,
            autoplay: true,
        };
        const { View } = useLottie(options);
        return View;
    };

    const items = [
        {
            label: 'Personal',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'First Step', detail: event.item.label });
            }
        },
        {
            label: 'Seat',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Second Step', detail: event.item.label });
            }
        },
        {
            label: 'Payment',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Third Step', detail: event.item.label });
            }
        },
        {
            label: 'Confirmation',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Last Step', detail: event.item.label });
            }
        }
    ];


    const formik = useFormik({
        initialValues: {
            Name: "",
            Mobile: "",
            Email: "",
            School: "",
            Website: "",
            Designation: "",
            City: "",
            Message: ""
        },

        validationSchema: Yup.object({
            Name: Yup.string()
                .max(15, "Must be 15 characters or less")
                .required("Required"),
            Mobile: Yup.number().min(10, "Must be 10 characters").required("Required"),
            Email: Yup.string().email("Invalid email address").required("Required"),
            School: Yup.string().required("Required"),
            Website: Yup.string(),
            Designation: Yup.string().required("Required"),
            City: Yup.string().required("Required"),
            Message: Yup.string().required("Required"),
        }),
        onSubmit: values => {
            setFormStep(1)
            alert(JSON.stringify(values, null, 2));
        },
    });

    const FormSteps = (formStep) => {
        switch (formStep) {
            case 1:
                return (
                    <>
                        <div className="ContactUs-left-address-sidebar">
                            Personal Information
                        </div>
                        <div className="contact-form-input-warp-home">
                            <label className="label-home" htmlFor="Name">
                                Full Name
                            </label>
                            <input
                                className="form-input-sidebar"
                                id="Name"
                                name="Name"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Name}
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                                <div className="contact-error">{formik.errors.Name}</div>
                            ) : null}
                        </div>
                        <div className="contact-form-input-warp-home">
                            <label className="label-home" htmlFor="Mobile">
                                Mobile
                            </label>
                            <input
                                className="form-input-sidebar"
                                id="Mobile"
                                name="Mobile"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Mobile}
                            />
                            {formik.touched.Mobile && formik.errors.Mobile ? (
                                <div className="contact-error">{formik.errors.Mobile}</div>
                            ) : null}
                        </div>
                        <button className="contact-submit-home" onClick={() => {
                            if (formik.values.Name === "" || formik.values.Mobile === "") {
                                alert("please fill the required details");
                            } else {
                                setFormStep(2)
                            }
                        }}>
                            NEXT
                        </button>
                    </>
                )

            case 2:
                return (
                    <>
                        <div className="ContactUs-left-address-sidebar">
                            Organizational Information
                        </div>
                        <div className="contact-form-input-warp-home">
                            <label className="label-home" htmlFor="School">
                                School/Organization
                            </label>
                            <input
                                className="form-input-sidebar"
                                id="School"
                                name="School"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.School}
                            />
                            {formik.touched.School && formik.errors.School ? (
                                <div className="contact-error">{formik.errors.School}</div>
                            ) : null}
                        </div>

                        <div className="contact-form-input-warp-home">
                            <label className="label-home" htmlFor="Website">
                                Website
                            </label>
                            <input
                                className="form-input-sidebar"
                                id="Website"
                                name="Website"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Website}
                            />
                            {formik.touched.Website && formik.errors.Website ? (
                                <div className="contact-error">{formik.errors.Website}</div>
                            ) : null}
                        </div>

                        <div className="SlideControllButton">
                            <button className="contact-submit-home" onClick={() => { setFormStep(1) }}>
                                Back
                            </button>

                            <button className="contact-submit-home" onClick={() => { 
                                if (formik.values.School === "" || formik.values.Website === "") {
                                    alert("please fill the required details");
                                } else {
                                    setFormStep(3)
                                }
                                }}>
                                NEXT
                            </button>
                        </div>


                    </>
                )

            case 3:
                return (
                    <>
                    <div className="ContactUs-left-address-sidebar">
                            Address Information
                        </div>
                    <div className="contact-form-input-warp-home">
                        <label className="label-home" htmlFor="Address">
                        Designation
                        </label>
                        <input
                            className="form-input-sidebar"
                            id="Address"
                            name="Address"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Designation}
                        />
                        {formik.touched.Designation && formik.errors.Designation ? (
                            <div className="contact-error">{formik.errors.Designation}</div>
                        ) : null}
                    </div>
                    
                    <div className="contact-form-input-warp-home">
                        <label className="label-home" htmlFor="City">
                            City
                        </label>
                        <input
                            className="form-input-sidebar"
                            id="City"
                            name="City"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.City}
                        />
                        {formik.touched.City && formik.errors.City ? (
                            <div className="contact-error">{formik.errors.City}</div>
                        ) : null}
                    </div>

                    <div className="SlideControllButton">
                            <button className="contact-submit-home" onClick={() => { setFormStep(2) }}>
                                Back
                            </button>

                            <button className="contact-submit-home" onClick={() => { 
                                if (formik.values.Address === "" || formik.values.City === "") {
                                    alert("please fill the required details");
                                } else {
                                    setFormStep(4)
                                }
                                }}>
                                NEXT
                            </button>
                        </div>

                </>
                )

            case 4:
                return (
                    <>
                    <div className="ContactUs-left-address-sidebar">
                            Please Tell us something
                        </div>
                        <div className="contact-form-input-warp-home">
                            <label className="label-home" htmlFor="Message">
                                Message
                            </label>
                            <textarea
                                className="form-text-home"
                                id="Message"
                                name="Message"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Message}
                            />
                            {formik.touched.Message && formik.errors.Message ? (
                                <div className="contact-error">{formik.errors.Message}</div>
                            ) : null}
                        </div>
                        <div className="SlideControllButton">
                            <button className="contact-submit-home" onClick={() => { setFormStep(3) }}>
                                Back
                            </button>

                            <button className="contact-submit-home" type="submit">
                            SUBMIT
                        </button>
                        </div>
                        
                    </>
                )
        }
    }

    const sidebarStyles = {
        width: "50vw"
      };

    return (
        <>
            <div className="slide-form-button" onClick={() => setVisible(true)}> <a> GET IN TOUCH </a></div>

            <Sidebar className="sidebarStyles" position="right" visible={visible} onHide={() => setVisible(false)} >
                <div className="ContactUs-main-container-sidebar">
                    {/* <div className="ContactUs-left-container"> */}
                        {/* <div className="ContactUs-left-title">
                            CONTACT US
                        </div> */}
                        {/* <div className="ContactUs-left-address">
                            We're Always ready
                        </div> */}
                        {/* <div className="Contact-left-content-container">
                            <div className="ContactUs-left-content">
                                110-111 Laxmi Tower, Commercial Complex Azadpur, Delhi - 11003
                            </div>
                            <div className="ContactUs-left-content">
                                +91 8700763221
                            </div>
                            <div className="ContactUs-left-content">
                                sales@genericsoftware.in
                            </div>
                        </div> */}
                        {/* <div className="ContactUs-left-gif">
                            <ArrowLottie />
                        </div> */}
                    {/* </div> */}

                    {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

                    <div className="ContactUs-right-container-slidebar">
                        <div className="ContactUs-right-form-sidebar">
                            <form className="form-container" onSubmit={formik.handleSubmit}>

                                {/* {FormSteps(formStep)} */}

                                <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="Name">
                Full Name
              </label>
              <input
                className="form-input-sidebar"
                id="Name"
                name="Name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Name}
              />
              {formik.touched.Name && formik.errors.Name ? (
                <div className="contact-error">{formik.errors.Name}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="Mobile">
                Mobile
              </label>
              <input
                className="form-input-sidebar"
                id="Mobile"
                name="Mobile"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Mobile}
              />
              {formik.touched.Mobile && formik.errors.Mobile ? (
                <div className="contact-error">{formik.errors.Mobile}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="School">
                School/Organization
              </label>
              <input
                className="form-input-sidebar"
                id="School"
                name="School"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.School}
              />
              {formik.touched.School && formik.errors.School ? (
                <div className="contact-error">{formik.errors.School}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="Website">
                Website
              </label>
              <input
                className="form-input-sidebar"
                id="Website"
                name="Website"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Website}
              />
              {formik.touched.Website && formik.errors.Website ? (
                <div className="contact-error">{formik.errors.Website}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="Designation">
              Designation
              </label>
              <input
                className="form-input-sidebar"
                id="Designation"
                name="Designation"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Designation}
              />
              {formik.touched.Designation && formik.errors.Designation ? (
                <div className="contact-error">{formik.errors.Designation}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="City">
                City
              </label>
              <input
                className="form-input-sidebar"
                id="City"
                name="City"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.City}
              />
              {formik.touched.City && formik.errors.City ? (
                <div className="contact-error">{formik.errors.City}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp-home">
              <label className="label-home" htmlFor="Message">
                Message
              </label>
              <textarea
                className="form-text-sidebar"
                id="Message"
                name="Message"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Message}
              />
              {formik.touched.Message && formik.errors.Message ? (
                <div className="contact-error">{formik.errors.Message}</div>
              ) : null}
            </div>

            <button className="contact-submit-sidebar" type="submit">
              SUBMIT
            </button>



                            </form>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    )
}
export default SlideForm