
import "./MobileSectionTwo.css";
// import content from "../../../Model/whygeneric.content.json";
import { useState } from "react";
// import { TabMenu } from "primereact/tabmenu";

const MobileSectionTwo = (image, description) => {
  // const [Contents, setContents] = useState(content[0]);
  const [currentId, setCurrentId] = useState(1);

  const handleButtonClick = (index) => {
    setCurrentId(index);
  };

  const CustomButton = ({ title, index, isActive, handleClick  }) => {

    return (
      <div
        className={`Custom_Button ${isActive ? 'active' : ''}`}
        onClick={() => {
          handleClick(index);
        }}
      >
        {title}
      </div>
    );
  };


  const item1 = [
    {
      id: 1,
      title: "SIBLINGS' CONVENIENCE",
      properties: {
        image:"https://img.freepik.com/free-photo/successful-schoolgirl_1098-13593.jpg?t=st=1684932076~exp=1684932676~hmac=d680932286f4e9cceca2eb464013c250e0e48874bf8ef2380abfb84c88763f43",
        description: "In order to use the effective mobile app features and monitor the functioning of the ward. In order to verify the information of the pupils without missing anything, parents must log in using the login credentials to the mobile app. Parents may have many kids enrolled in the same school/college. The trouble-free login provided by our smart mobile software requires simply the usage of a single user ID for several wards. With only one family login ID, parents may see all of the information about the worried kid thanks to the Switch Ward functionality. Additionally, the app has a useful function called Forgot Password that enables parents to quickly reset their credentials in the event that they lose them."
      },
    },
    {
      id: 2,
      title: "ADMIN ACCESS",
      properties: {
        description:
          "To evaluate success, leaders must regularly check on the location of their school/college. This may be accomplished anywhere, at any time, even on the road using a dependable mobile learning app. The EduAppz gives administrators access to summarised reports for fee collection, total student and staff attendance, and  admission-related data. These kinds of studies assist in determining the areas that need to be prioritised in order to provide stronger results and, ultimately, contribute to the organization's success.",
      image:"https://img.freepik.com/free-photo/smiling-business-lady-working-with-colleagues_1262-2153.jpg?t=st=1684932239~exp=1684932839~hmac=76b3b15760d3c28479764378ffcf174a903a74dfa08a8cc3932ac691e557cac1"
        },
    },
    {
      id: 3,
      title: "HOMEWORK HUB",
      properties: {
        image:"https://img.freepik.com/free-photo/abstract-blur-defocused-bookshelf-library_1203-9640.jpg?t=st=1684932318~exp=1684932918~hmac=3a7690f3d60ce56c0d4a0d85420a4c332283edb1a7927646d3b37fa4cf7ea63e",
        description:
          "Homework is equally vital to kids as learning resources and attendance. This software makes sure the procedure go forward without incident. Students can check their assignments by topic and submit it using the app. Additionally, this software enables teachers to post assignments for their pupils in a variety of formats, including.doc,.pdf,.ppt, etc. When fresh homework is available for pupils, the smart alert option tells the student. such that not a single crucial update is overlooked.",
      },
    },
    {
      id: 4,
      title: "E-LEARNING WORLD",
      properties: {
        image:"https://www.pngmart.com/files/7/Support-PNG-Transparent-Picture.png",
        description:
          "Undoubtedly, one of the most effective methods of education is the modern, digitalized manner of learning and teaching. EduAppz makes sure that learning never stops and speeds up the rate of advancement. This dependable mobile app offers the ability to join a live session and easily access all the learning resources offered in different forms. In order to make it simple to find the necessary study resources, all study materials are grouped by subject and chapter. Additionally, this app alerts the student when the subject instructor promptly arranges a lesson. Additionally, this app offers a direct connection that can be used to join to the classes that are planned for Zoom or Google Meet.",
      },
    },
    {
      id: 5,
      title: "ONLINE SIMPLICITY",
      properties: {
        image:"https://img.freepik.com/free-photo/businessman-using-application-contact-with-his-colleagues_1134-644.jpg?t=st=1684932547~exp=1684933147~hmac=479490078c934d88bbc5f385a341959323da4dba88e170fdb229ae258b93fa3a",
        description:
          "Parents' concerns about fee payments constantly arise. Because they need to rush to the schools/colleges, wait in queue and for some parents, submitting payments requires them to take a day out of work. Additionally, there are additional restrictions like vacations and handling charge receipts. With the help of this clever mobile app for schools, all restrictions and problems associated with online tuition payment are resolved. Now, paying fees is as easy as clicking a button, and it can be done from anywhere, at any time, even on a workday.",
      },
    },
    {
      id: 6,
      title: "TECH HELP",
      properties: {
        image:"https://img.freepik.com/free-photo/medium-shot-colleagues-discussing-work_23-2149622839.jpg?t=st=1684932641~exp=1684933241~hmac=86571487d4dc2b36a863c241cd1532df55128d3b023c19969d62e38f896f06cd",
        description:
          "Currently, there are a lot of mobile applications for education available. But the issue that comes here is whether parents will be able to contact the appropriate person who can assist them in finding answers if they encounter any problems. Here, it distinguishes us from the competition. EduAppz offers specialised parent helpdesk service to assist parents with any questions they may have and to deliver satisfactory answers. Additionally, the technical support staff and mobile app nerds are working together to fix the stated problem or defect as soon as feasible.",
      },
    },
    {
      id: 7,
      title: "ONLINE TESTING",
      properties: {
        image:"https://img.freepik.com/free-photo/man-studying-grammar-with-his-colleagues_52683-107810.jpg?t=st=1684932762~exp=1684933362~hmac=84c75f0e104b3bd96608bb87286bc5fb45a6601367b721fd0b4acfe5b305db9d",
        description:
          "A solid alternative for assessing student comprehension of a specific chapter in class is to administer an online quiz. It assists the subject teacher in assessing the student's understanding of the chapter so that future lessons may be scheduled appropriately. This smart app for education is more accessible thanks to smartphones. The quiz may be completed without any problems by using the app. In addition, when the quiz is over, quick results are generated. so that pupils can gauge their current development.",
      },
    },
    {
      id: 8,
      title: "DAILY TRACKER",
      properties: {
        image:"https://img.freepik.com/free-photo/phone-computer-graphic-showing-mobile-phone-lifestyle_1134-771.jpg?t=st=1684932869~exp=1684933469~hmac=ea4507bebab2070d4f0ab3254d48d641d523ed53f5e7ff990ceae3ba32f975f6",
        description:
          "By providing the parents and employees with the most recent updates via the mobile app, Smart Alert features help users keep one step ahead. In addition, parents may use the app to monitor students' attendance, daily comments, and even report cards. Between parents and concerned school officials including the principal, class teacher, and even the subject instructor, this effective mobile app facilitates easy communication. It enables parents to view the curriculum and circular for their child. Students and staff may use this app to apply for leave as well, and it's a simple and effective way to manage everything .",
      },
    }
  ];


  return (
    <div className="mobileSection-main">
      <div className="mobileSection-main-warp">

      <div className="mobileSection_title">
        <div className="mobileSection-Text">Important Features of a Mobile School Management App</div>
        </div>
      <div className="mobileSection-subtitle">
        <div className="mobileSection-subtitle-content">
          {/* <TabMenu model={items} /> */}
          {item1.map((item, index) => {
           return <CustomButton 
           key={index}
           title={item.title} 
           index={item.id} 
           isActive={currentId === item.id}
           handleClick={handleButtonClick} />;
          })}
        </div>
      </div>
      </div>
      <div className="mobileSectionContant-main">
        {/* <div className="mobileSectionContant-section-left">
          <div className="mobileSectionContent-image">
            <img src={item1[currentId-1]?.properties.image}alt="" />
          </div>
        </div> */}
        <div className="mobileSectionContant-section-right">
          <div className="mobileSectionContent-text">
            {item1[currentId-1]?.properties.description}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileSectionTwo;
