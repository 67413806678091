import "./testimonial.component.css";

const TestimonialBox = ({ image, title, description,subtitle }) => {
  const mobile_res = window.screen.width <= 720

  const cardHeight = !mobile_res ? "60vh" : "fit-content"

  return (
    <div className="testimonial-box-container" style={{height:cardHeight}}>
      <div className="testimonial-image-container">
        <img draggable={false} className="testimonial-image" src={image} alt="" />
      </div>
      <div className="testimonial-text-container">
      <h1 className="testimonial-title">{title}</h1>
      <h1 className="testimonial-subtitle">{subtitle}</h1>
        <h1 className="testimonial-description">"{description}"</h1>
      
      </div>
    </div>
  );
};
export default TestimonialBox;
