import "./home_testimonials.css";
import CarouselSlider from "../../../Components/slide_test/carousel.component";
import testimonial1 from "../../../Assets/Testimonials/aaravali.svg"
import testimonial2 from "../../../Assets/Testimonials/goodsamarian.svg"
import testimonial3 from "../../../Assets/Testimonials/norte.svg"
import testimonial4 from "../../../Assets/Testimonials/Bhanasthi.svg"
import testimonial5 from "../../../Assets/Testimonials/joseph.svg"
import testimonial6 from "../../../Assets/Testimonials/guru.svg"

const TestimonialsCarousel = () => {
  const ContentJson = {
    "testimonials": [
        {
            "image": testimonial1,
            "title": "ARAVALI COLLEGE OF ENGG AND MGMT",
            "description": "Best tool for teachers and students. We can rely on EduHub for our grades. communication, and posting information and links. We have been using it for yearsnow, and it just gets better every year. Icannot imagine a day without it.",
            "subtitle":"Empowering educators, students, and parents for success  "
          },
        {
            "image": testimonial2,
            "title": "GOOD SAMARITAN SCHOOL",
            "description": "EduHub and EduAppz have revolutionized our school management! Streamlined processes, user-friendly interfaces, and seamless mobile access. Highly  recommended for efficient school ERP and mobile app integration!",
            "subtitle":"Experience the future of educational management!"
          },
        {
            "image": testimonial3,
            "title": "NOTRE DAME SCHOOL",
            "description": "EduHub and EduAppz are a game-changer for our school! The ERP and mobile app have simplified administrative tasks, enhance communication, and improved student management. Highly recommended!",
            "subtitle":"A game-changer for schools!! The best one can get."
          },
        {
            "image": testimonial4,
            "title": "BANASTHALI PUBLIC SCHOOL",
            "description": "Streamlined school management made easy with EduHub and EduAppz. Efficient data management, seamless communication, and user-friendly interface.An essential tool for modern schools. Highly recommended!",
            "subtitle":"Simplifies school managemen with an intuitive Software!"
          },{
          "image": testimonial5,
          "title": "JOSEPH & MARY PUBLIC SCHOOL",
          "description": " There ERP have transformed our administrative processes. The convenience of accessing information and managing tasks on-the-go has been invaluable. Highly recommended for modern schools!",
          "subtitle":"Game changer for school administration!"
        },
      {
        "image": testimonial6,
        "title": "GURU TEGH BAHADUR PUBLIC SCHOOL",
        "description": "Efficient school ERP and mobile app.Simplifies school management, enhances communication, and streamlines operations.User-friendly, reliable, and highly recommended for schools.",
        "subtitle":"Simplifying school management and boosting productivity."
      }
    ]
}
  return (
    <div className="testimonials-carousel-main-container">
      <div className="testimonials-carousel-title">Testimonials</div>
      <div className="testimonials-carousel-subtitle">
          We Go Extra Miles Together
        </div>
        <div className="testimonials-carousel-description">
          We've got decades-long relationships with customers, See what a Customer says.
        </div>
      <CarouselSlider
        type={3}
        data={ContentJson.testimonials}
        autoPlay={true}
        slidesToSlide={0.25}
        transition="all 1s linear"
      />
    </div>
  );
};

export default TestimonialsCarousel;
