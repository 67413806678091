import React from "react";
import "./carousel.component.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import ProjectBox from "../projects_box/project.component";
// import SolutionBox from "../solutions_box/solution.component";
// import FrameworkBox from "../framework_box/framwork.component";
import TestimonialBox from "../testimonial_box/testimonial.component";


/**
 * @param type :: 0->project card; 1->solution card; 2->framework card; 3->testimony card; 4->Simple card; 5->single card
 * */

function CarouselSlider({
  type,
  data,
  autoPlaySpeed = 1,
  slidesToSlide = 1,
  rtl = false,
  autoPlay = false,
  transition = "",
  transitionDuration = 1000,
  responsive,
}) {
  return (
    <div className="slider-container">
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        centerMode={false}
        // className="slider-container"
        containerClass="container-with-dots"
        customTransition={transition}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        // itemClass="slider-card"
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover={true}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={
          responsive ?? {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 5,
            },
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }
        }
        rewind={false}
        rewindWithAnimation={false}
        rtl={rtl}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={slidesToSlide}
        swipeable
        transitionDuration={transitionDuration}
      >
        {Object.entries(data).map(
          ([key, value]) =>
            ({
              // 0: (
              //   <div className="slider-card">
              //     <ProjectBox
              //       image={value.image}
              //       title={value.title}
              //       description={value.description}
              //     />
              //   </div>
              // ),
              // 1: (
              //   <div className="slider-card">
              //     <SolutionBox image={value.image} title={value.title} />
              //   </div>
              // ),
              // 2: (
              //   <div className="slider-card">
              //     <FrameworkBox image={value.image} title={value.title} />
              //   </div>
              // ),
              3: (
                <div className="slider-card">
                  <TestimonialBox
                    image={value.image}
                    title={value.title}
                    description={value.description}
                    subtitle={value.subtitle}
                  />
                </div>
              ),
              // 4: (
              //   <div className="slider-card">
              //     <ProjectBox
              //       image={value.image}
              //       title={value.title}
              //       description={value.description}
              //     />
              //   </div>
              // ),
              // 5: (
              //   <div key={key} style={{ position: "relative" }}>
              //     <img
              //       className="fill"
              //       draggable={false}
              //       alt="text"
              //       style={{ width: "100%", height: "100vh" }}
              //       src={value}
              //     />
              //     <div className="circleBase">
              //       {key}/{data.length - 1}
              //     </div>
              //   </div>
              // ),
            }[type])
        )}
      </Carousel>
    </div>
  );
}

export default CarouselSlider;
