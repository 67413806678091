import "./Seo.css";
import SectionOne from "../../../Components/Sectionone/Sectionone";
// import logo from "../../../Assets/landingPage/carousel-2.png";
import ContentTemplate from "../../../Components/contentTemplate/ContentTemplate";
import {services_heroSection_Content} from "../../../Model/HeroSection.content"

const Seo =()=>{
    const SeoContant = services_heroSection_Content.SEO
    return(
        <div className="seo-main-container">
    
      <SectionOne
        title={SeoContant.title}
        description={SeoContant.description}
        image={SeoContant.image}
        subtitle={SeoContant.subtitle}
        subimage={SeoContant.subimage}
      />
        <ContentTemplate description={SeoContant.subtitle} image={SeoContant.subimage} isReverse={false} />
      </div>
    );
};
export default Seo;